import { Component, Input, NgModule, OnDestroy,TemplateRef } from '@angular/core';
import { ViewCell } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { EditLocationComponent} from './edit-location/edit-location.component';
import { DeleteLocationComponent} from './delete-location/delete-location.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-btn-locations',
  // tslint:disable-next-line:max-line-length
  template: `<span class="d-flex full-container"><button nbTooltip="Modifier ce lieu" (click)="openEditWindow()" nbButton ghost [status]="editing ? 'info' : 'danger'"><nb-icon icon="edit"></nb-icon></button><button class="ml-1" nbTooltip="Supprimer cette question" (click)="open(false)" nbButton ghost [status]="danger"><nb-icon icon="trash-2-outline"></nb-icon></button></span>`,
})

@NgModule()
export class LocationsEditBtnComponent implements ViewCell, OnDestroy {
  renderValue: string;
  editing = false;
  private subscriptions$ = new Subscription();
  
  @Input() value: string | number;
  @Input() rowData: any;

  constructor(
    private windowLocation1: NbWindowService,private dialogLocation: NbDialogService) { }

    //modal delete alert
    private dialogRefLocation:NbDialogRef<any>;
    // openWithBackdropClick() {
    //   this.open(true);
    // }
  
    // openWithoutBackdropClick() {
    //   this.open(false);
    // }
  
  
     open(closeOnBackdropClick: boolean) {
      this.dialogRefLocation= this.dialogLocation.open(DeleteLocationComponent, {context: {location: this.rowData },closeOnBackdropClick:closeOnBackdropClick});
    }
  //end modal delete alert

  openEditWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowLocation1.open(EditLocationComponent, {
        title: 'Modifier ce lieu',
        context: { location: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
