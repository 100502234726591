import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { NbLogoutComponent, NbAuthService, NB_AUTH_OPTIONS, NbAuthResult, getDeepFromObject } from '@nebular/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout-page',
  templateUrl: './logout-page.component.html',
  styleUrls: ['./logout-page.component.scss']
})
export class LogtoutPageComponent implements OnInit {

  constructor(
    protected auth: AuthService,
    protected router: Router
  ) { }

  ngOnInit(): void {
    this.logout();
  }

  logout(): void {
    this.auth.logout();
    
    this.router.navigate(['/login']);

   
  }

}
