import { Component, Input, NgModule, OnDestroy,TemplateRef } from '@angular/core';
import { ViewCell } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { ModifyQuestionComponent} from './modify-question/modify-question.component';
import { DeleteQuestionComponent} from './delete-question/delete-question.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-questions',
  // tslint:disable-next-line:max-line-length
  template: `<span class="d-flex full-container"><button nbTooltip="Modifier cette question" (click)="openEditWindow(row.data)" nbButton ghost [status]="editing ? 'info' : 'danger'"><nb-icon icon="edit"></nb-icon></button><button class="ml-1" nbTooltip="Supprimer cette question" (click)="open(false,row.data)" nbButton ghost [status]="danger"><nb-icon icon="trash-2-outline"></nb-icon></button></span>`,
})

@NgModule()
export class QuestionsEditBtn implements ViewCell, OnDestroy {
  renderValue: string;
  editing = false;
  private subscriptions$ = new Subscription();
  
  @Input() value: string | number;
  @Input() rowData: any;
  @Input() row: any;

  constructor(
    private windowQuestion1: NbWindowService,private dialogQuestion: NbDialogService) { }

    //modal delete alert
    private dialogRefQuestion:NbDialogRef<any>;
    // openWithBackdropClick() {
    //   this.open(true);
    // }
  
    // openWithoutBackdropClick() {
    //   this.open(false);
    // }
  
  
     open(closeOnBackdropClick: boolean,row:any) {
      
       console.log("row:",row)
      this.dialogRefQuestion= this.dialogQuestion.open(DeleteQuestionComponent, {context: {question:row },closeOnBackdropClick:closeOnBackdropClick});
    }
  //end modal delete alert

  openEditWindow(row) {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion1.open(ModifyQuestionComponent, {
        title: 'Modifier cette question',
        context: { question: row }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  // openDeleteWindow() {
  //   this.editing = true;
  //   this.subscriptions$.add(
  //     this.windowService.open(ModifyServiceComponent, {
  //       title: 'Supprimer ce service',
  //       context: { user: this.rowData }
  //     }).onClose.subscribe(() => {
  //       this.editing = false;
  //     })
  //   );
  // }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
