import { Component, OnInit, OnDestroy, Input } from '@angular/core';

@Component({
  selector: 'app-users-stats',
  templateUrl: './users-stats.component.html',
  styleUrls: ['./users-stats.component.scss']
})
export class UsersStatsComponent implements OnInit {
  @Input() dashboardData;

  constructor() {
  }

  ngOnInit() {
  }


}
