// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-modify-service',
//   templateUrl: './modify-service.component.html',
//   styleUrls: ['./modify-service.component.scss']
// })
// export class ModifyServiceComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { ApiService } from '../../api-services.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modify-service',
  templateUrl: './modify-service.component.html',
  styleUrls: ['./modify-service.component.scss']
})
export class ModifyServiceComponent implements OnInit, OnDestroy {
  serviceForm: FormGroup; 
  submitted: boolean;
  min: Date;
  service: any;
  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbWindowRef,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.serviceForm = new FormGroup({
     
      name: new FormControl(this.service.name ? this.service.name : null, Validators.required),
      
    });
  }

  onSubmit() {
    if (this.serviceForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
    const formData = {};
    Object.keys(this.serviceForm.value).forEach(key => {
      if (this.serviceForm.get(key).value && this.serviceForm.get(key).value !== this.service[key]) {
        formData[key] = this.serviceForm.get(key).value;
      }
    });

    this.subscriptions$.add(
      this.apiService.updateService(this.service._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données du service sont mises à jour.');
            this.serviceForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/services-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de modification!');
          }
        }
      )
    );
  }

  handleWrongInput() {
    Object.keys(this.serviceForm.controls).forEach(key => {
      if (this.serviceForm.get(key).errors) {
        switch (key) {
          case 'name':
            this.toastService.warning('Saisir un nom.', 'Name required');
            break;
         
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

