import { Component, OnInit, OnDestroy, ViewChild, Directive } from '@angular/core';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TasksService } from 'src/app/core/services/tasks.service';
import { UsersService } from 'src/app/core/services/users.service';
import { ReferentialService } from 'src/app/core/services/referential.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxPrintDirective } from 'ngx-print';
import { ExportAsService, ExportAsConfig } from 'ngx-export-as';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
declare const ExcelJS: ExcelJS;

@Component({
    selector: 'app-export-window-window',
    templateUrl: './export-window.component.html',
    styleUrls: ['./export-window.component.scss']
})
export class ExportWindowComponent implements OnInit, OnDestroy {
    exportForm: FormGroup; submitted: boolean;
    private subscriptions$ = new Subscription();
    ref: any;
    services: any;
    questions: any;
    printData;
    excelData;
    user;
    config;
    today;
    tableRight;
    @ViewChild(NgxPrintDirective, { static: false }) printDirective: NgxPrintDirective;

    excelConfig: ExportAsConfig = {
        type: 'xls',
        elementIdOrContent: 'excel-template',
        options: {}
    };


    constructor(
        protected windowRef: NbWindowRef,
        private dateService: NbDateService<Date>,
        private auth: AuthService,
        private tasksService: TasksService,
        private usersService: UsersService,
        private toastService: NbToastrService,
        private refService: ReferentialService,
        private exportAsService: ExportAsService,
        private router: Router
    ) {
    }
    ngOnInit() {
        this.today = new Date();
        this.initForm();
        this.subscriptions$.add(this.refService.ref.subscribe(data => {
            if (data) {
                this.ref = data;
                this.services = data.services;
                this.questions = data.questions;
            }
        }));
    }

    private initForm() {
        this.exportForm = new FormGroup({
            location: new FormControl(null, Validators.required),
            services: new FormControl([]),
            questions: new FormControl([]),
            range: new FormControl(null),
        });
    }

    onSubmit(mode) {
        if (this.exportForm.invalid) {
            return;
        }
        this.submitted = true;
        console.log(mode);

        const formData = {
            location: this.exportForm.value.location._id,
            services: this.exportForm.value.services ? this.exportForm.value.services.map(s => s ? s._id : null) : null,
            questions: this.exportForm.value.questions,
            rangeStart: this.exportForm.value.range ? this.exportForm.value.range.start : null,
            rangeEnd: this.exportForm.value.range ? this.exportForm.value.range.end : null
        };

        this.subscriptions$.add(
            this.tasksService.getExportData(formData).subscribe(
                async data => {
                    this.submitted = false;
                    if (data && Array.isArray(data)) {
                        if (data.length) {
                            this.toastService.success('', 'L\'exportation est prête');
                            if (mode === 'PRINT') {
                                this.printData = this.changePrintData(data);
                                this.config = {
                                    location: this.exportForm.value.location.name,
                                    // tslint:disable-next-line:max-line-length
                                    services: this.exportForm.value.services ? this.exportForm.value.services.map(s => s ? s.name : null).join(', ') : null,
                                    rangeStart: this.exportForm.value.range ? this.exportForm.value.range.start : null,
                                    rangeEnd: this.exportForm.value.range ? this.exportForm.value.range.end : null
                                };
                                setTimeout(() => { this.printDirective.print(); }, 1);
                            } else if (mode === 'EXCEL') {
                                this.excelData = await this.changeExcelData(data);
                                this.config = {
                                    location: this.exportForm.value.location.name,
                                    // tslint:disable-next-line:max-line-length
                                    services: this.exportForm.value.services.filter(s => s.name),
                                    rangeStart: this.exportForm.value.range ? this.exportForm.value.range.start : null,
                                    rangeEnd: this.exportForm.value.range ? this.exportForm.value.range.end : null
                                };

                                setTimeout(async () => {
                                    await this.exportAsExcelFile();
                                    /*
                                    this.subscriptions$.add(
                                        this.exportAsService.save(this.excelConfig, 'myFile').subscribe(() => {
                                            return;
                                        })
                                        );
                                       */
                                }, 1);

                            }
                        } else {
                            this.toastService.warning('Aucune réponse n\'existe avec ces paramètres', 'L\'exportation a échoué');
                        }
                    } else {
                        this.toastService.danger('Erreur du serveur', 'L\'exportation a échoué');
                    }
                }
            )
        );
    }

    async exportAsExcelFile() {
        const d = new Date();
        const fileName = `Rapport_etude_${d.getDate().toString()}_${d.getMonth().toString()}_${d.getFullYear().toString()}.xlsx`;

        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'ISO 21001';
        workbook.lastModifiedBy = 'ISO 21001';
        workbook.created = new Date();
        workbook.modified = new Date();
        workbook.calcProperties.fullCalcOnLoad = true;

        const sheet = workbook.addWorksheet('Rapport', { properties: { tabColor: { argb: 'FFC0000' } } });
        sheet.state = 'visible';
        sheet.pageSetup.margins = {
            left: 0.7, right: 0.7,
            top: 0.75, bottom: 0.75,
            header: 0.3, footer: 0.3
        };
        sheet.properties.defaultColWidth = 25;

        this.drawExcelTable(sheet);

        const buff = workbook.xlsx.writeBuffer().then(
            (data) => {
                const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.id = 'downloadExcel';
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
                // const el = document.getElementById('downloadExcel');
                // el.parentNode.removeChild(el);
            }
        );
    }

    drawExcelTable(sheet) {
        this.tableRight = (this.config.services && this.config.services.length >= 4) ? this.config.services.length + 1 : 4;
        let r = 1;

        r = this.addExcelHeaders(sheet);
        if ((this.config.services && this.config.services.length > 0) || this.config.rangeStart || this.config.rangeEnd) {
            this.addEmptyRow(sheet, r);
            r++;
        }
        r = this.addExcelSearchParameters(sheet, r);

        r = this.addTableDataHeader(sheet, r);
        r = this.addData(sheet, r);
    }

    addData(sheet, r) {
        if (!this.excelData || !(this.excelData.length > 0)) {
            console.log('No excel data.');
            return;
        }
        for (const obj of this.excelData) {
            let i = 1;
            for (const resp of obj.responses) {
                const row = sheet.getRow(r);

                // If it's the first response then insert the question
                if (i === 1) {
                    sheet.mergeCells(r, 1, r + obj.rowspan - 1, 1);
                    row.getCell(1).value = obj.question.text;
                    this.addBorderAndAlignment(row.getCell(1));
                    this.setUnderlined(row.getCell(1));
                }
                // tslint:disable-next-line:max-line-length
                row.getCell(2).value = this.toTitleCase((resp.task ? (resp.task.user ? (resp.task.user.lastName ? resp.task.user.lastName + ' ' : '') + (resp.task.user.firstName ? resp.task.user.firstName : '') : '') : ''));
                this.addBorderAndAlignment(row.getCell(2));

                row.getCell(3).value = this.toTitleCase(resp.task ? (resp.task.user ? resp.task.user.email : '') : '');
                this.addBorderAndAlignment(row.getCell(3));

                row.getCell(4).value = resp.comment;
                this.addBorderAndAlignment(row.getCell(4));

                i++;
                r++;
            }
        }
        return r;
    }

    addTableDataHeader(sheet, r) {
        sheet.mergeCells(r, 1, r + 1, 1);
        const qAndA = sheet.getRow(r);
        qAndA.getCell(1).value = 'Questions';
        this.addBorderAndAlignment(qAndA.getCell(1));
        this.setBold(qAndA.getCell(1));

        sheet.mergeCells(r, 2, r, 4);
        qAndA.getCell(2).value = 'Réponses';
        this.addBorderAndAlignment(qAndA.getCell(2));
        this.setBold(qAndA.getCell(2));
        r++;

        const titles = sheet.getRow(r);
        titles.getCell(2).value = 'Utilisateur';
        this.addBorderAndAlignment(titles.getCell(2));
        this.setBold(titles.getCell(2));
        titles.getCell(3).value = 'Email';
        this.addBorderAndAlignment(titles.getCell(3));
        this.setBold(titles.getCell(3));
        titles.getCell(4).value = 'Commentaire';
        this.addBorderAndAlignment(titles.getCell(4));
        this.setBold(titles.getCell(4));
        r++;

        return r;
    }

    addExcelSearchParameters(sheet, r) {
        if (this.config.services && this.config.services.length > 0) {
            const servicesRow = sheet.getRow(r);

            const title = servicesRow.getCell(1);
            title.value = 'Services';
            this.addBorderAndAlignment(title);
            this.setBold(title);

            let i = 1;
            for (const s of this.config.services) {
                i++;
                const serv = servicesRow.getCell(i);
                serv.value = s.name;
                this.addBorderAndAlignment(serv);
            }

            for (let remainingI = 3 - this.config.services.length; remainingI > 0; remainingI--) {
                i++;
                const empty = servicesRow.getCell(i);
                empty.value = '';
                this.addBorderAndAlignment(empty);
            }
            r++;

            this.addEmptyRow(sheet, r);
            r++;
        }
        if (this.config.rangeStart || this.config.rangeEnd) {
            let rangeRow = sheet.getRow(r);

            const title = rangeRow.getCell(1);
            sheet.mergeCells(r, 1, r + 1, 1);
            title.value = 'Intervalle de dates';
            this.addBorderAndAlignment(title);
            this.setBold(title);

            rangeRow.getCell(2).value = 'De';
            this.addBorderAndAlignment(rangeRow.getCell(2));
            this.setBold(rangeRow.getCell(2));
            rangeRow.getCell(3).value = this.config.rangeStart ? this.getFormattedDate(this.config.rangeStart) : 'Indéfini';
            this.addBorderAndAlignment(rangeRow.getCell(3));
            this.setUnderlined(rangeRow.getCell(3));
            r++;

            rangeRow = sheet.getRow(r);
            rangeRow.getCell(2).value = 'Au';
            this.addBorderAndAlignment(rangeRow.getCell(2));
            this.setBold(rangeRow.getCell(2));
            rangeRow.getCell(3).value = this.config.rangeEnd ? this.getFormattedDate(this.config.rangeEnd) : 'Indéfini';
            this.addBorderAndAlignment(rangeRow.getCell(3));
            this.setUnderlined(rangeRow.getCell(3));
            r++;

            this.addEmptyRow(sheet, r);
            r++;
        }
        return r;
    }

    addEmptyRow(sheet, r) {
        sheet.mergeCells(r, 1, r, this.tableRight);
    }

    addExcelHeaders(sheet) {
        /** Header */
        sheet.mergeCells(1, 1, 1, this.tableRight);
        const header = sheet.getCell('A1');
        header.value = {
            richText: [
                { text: `Rapport de l\'étude de poste\n` },
                {
                    text: `${this.config.location}`,
                    font: { underline: true, bold: true }
                }]
        };
        // this.setBold(header);
        this.addBorderAndAlignment(header);
        this.setBold(header);

        /** Date du rapport */
        sheet.mergeCells(2, 1, 2, this.tableRight);
        const dateRapport = sheet.getCell('A2');
        dateRapport.value = {
            richText: [
                { text: `Date du rapport: ` },
                {
                    text: `${this.getFormattedDate(this.today)}`,
                    font: { underline: true, bold: true }
                }]
        };
        // this.setBold(dateRapport);
        this.addBorderAndAlignment(dateRapport);
        this.setBold(dateRapport);

        return 3; // new row cursor
    }
    setBold(cell) {
        if (cell.font) {
            cell.font.bold = true;
        } else {
            cell.font = {
                bold: true
            };
        }
    }
    setUnderlined(cell) {
        if (cell.font) {
            cell.font.underline = true;
        } else {
            cell.font = {
                underline: true
            };
        }
    }
    setAlignment(cell) {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    }
    addBorderAndAlignment(cell) {
        cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        cell.border = {
            top: { style: 'thin', color: { argb: 'FF000000' } },
            left: { style: 'thin', color: { argb: 'FF000000' } },
            bottom: { style: 'thin', color: { argb: 'FF000000' } },
            right: { style: 'thin', color: { argb: 'FF000000' } }
        };
    }
    getFormattedDate(date) {
        const ye = new Intl.DateTimeFormat('fr', { year: 'numeric' }).format(date);
        const mo = new Intl.DateTimeFormat('fr', { month: 'short' }).format(date);
        const da = new Intl.DateTimeFormat('fr', { day: '2-digit' }).format(date);
        return `${da} ${mo}, ${ye}`;
    }
    toTitleCase(s) {
        return s.split(' ').map(([firstChar, ...rest]) => firstChar.toUpperCase() + rest.join('').toLowerCase()).join(' ');

    }

    changeExcelData(data) {
        // Adds the rowspan to each question, delete question if it has no comments
        // Modifies each question object to have a rowspan
        data.forEach((q, i, arrOfQ) => {
            if (q.responses && q.responses.length > 0) {
                q.responses.filter(r => r.comment);
            }
            if (q.responses && q.responses.length > 0) {
                q.rowspan = q.responses.length;
            } else {
                arrOfQ.splice(i, 1);
            }
        });
        return data;
    }

    changePrintData(data) {
        // Groups images 2 by 2
        // Images gets transformed into an array of arrays, so images get grouped by 2
        data.forEach(question => {
            if (question.responses) {
                question.responses.forEach(response => {
                    if (response.images) {
                        response.imagesMatrix = [];
                        while (response.images.length) { response.imagesMatrix.push(response.images.splice(0, 2)); }
                        response.images = response.imagesMatrix;
                        delete response.imagesMatrix;
                    }
                });
            }
        });
        return data;
    }

    locationSelected(location) {
        if (location.services && location.services.length) {
            (this.exportForm.get('services') as FormControl).setValue([]);
            this.services = location.services;
            this.changeQuestions(location.services);
        }
    }

    changeQuestions(servicesArr) {
        (this.exportForm.get('questions') as FormControl).setValue([]);
        if (servicesArr && servicesArr.length) {
            this.questions = [];
            this.ref.questions.forEach(question => {
                if (question.services && question.services.length) {
                    if (servicesArr.some(service => question.services.includes(service._id))) {
                        this.questions.push(question);
                    }
                } else {
                    this.questions.push(question);
                }
            });
        } else {
            this.questions = this.ref.questions;
        }
    }

    ngOnDestroy() {
        this.subscriptions$.unsubscribe();
    }
}
