import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import {
  NbDialogRef,
  NbDateService,
  NbToastrService,
} from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiSheetService } from '../../api-sheet.service';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-sheet',
  templateUrl: './add-sheet.component.html',
  styleUrls: ['./add-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSheetComponent implements OnInit, OnDestroy {
  sheetForm: FormGroup;
  submitted = false;
  min: Date;
  sheet: any;
  isoOptions = [];
  subscriptions$ = new Subscription();
  excelData: any[] | null = null; // Extracted Excel data
  fileError: string | null = null;

  constructor(
    protected windowRef: NbDialogRef<any>,
    private dateService: NbDateService<Date>,
    private apiSheets: ApiSheetService,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
    this.loadIsoOptions();
  }

  private initForm() {
    this.sheetForm = new FormGroup({
      category: new FormControl(null, Validators.required),
    });
  }

  private loadIsoOptions() {
    this.isoOptions = [
      { title: "ISO 9001", description: "Quality management systems. " },
      { title: "ISO 14001", description: "Environmental management systems." },
      { title: "ISO 45001", description: "Occupational health and safety management systems." },
      { title: "ISO 27001", description: "Information security management systems." },
      { title: "ISO 22000", description: "Food safety management systems." },
      { title: "ISO 50001", description: "Energy management systems." },
      { title: "ISO 31000", description: "Risk management" },
      { title: "ISO 26000", description: "Social responsibility." },
      { title: "ISO 22301", description: "Business continuity management systems." },
      { title: "ISO 13485", description: "Quality management systems for medical devices." },
      { title: "ISO 10002", description: "Quality management ." },
      { title: "ISO 19011", description: "Guidelines for auditing management systems." },
      { title: "ISO 17025", description: "Testing and calibration laboratories." },
      { title: "ISO 15189", description: "Medical laboratories." },
      { title: "ISO 20121", description: "Event sustainability management systems." },
    ];
    this.sheetForm.value.category=null;
  }

  handleFileInput(event: Event): void {
    this.fileError = null;
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      this.fileError = 'Aucun fichier sélectionné.';
      return;
    }

    const file = input.files[0];
    if (!file.name.endsWith('.xlsx')) {
      this.fileError = 'Veuillez sélectionner un fichier Excel valide.';
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const binaryData = e.target?.result as string;
        const workbook = XLSX.read(binaryData, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const rawData = XLSX.utils.sheet_to_json(sheet);
        this.excelData = this.parseExcelData(rawData);
      } catch (error) {
        this.fileError = 'Erreur lors de la lecture du fichier.';
        console.error(error);
      }
    };
    reader.onerror = () => (this.fileError = 'Erreur lors du chargement du fichier.');
    reader.readAsBinaryString(file);
  }

  private parseExcelData(data: any[]): any[] {
    const result = [];
    let currentArticle = null;
    let currentProcessus = null;

    data.forEach((row) => {
      if (row.__EMPTY?.startsWith("Article")) {
        currentArticle = { ref: row.__EMPTY, title: row.__EMPTY_1,t:'article', processus: [] };
        result.push(currentArticle);
      } else if (/^[0-9]+\.[0-9]+$/.test(row.__EMPTY)) {
        currentProcessus = { ref: row.__EMPTY, title: row.__EMPTY_1,t:'processus', criteres: [] };
        currentArticle?.processus.push(currentProcessus);
      } else if (row.__EMPTY?.startsWith("Critère")) {
        currentProcessus?.criteres.push({ ref: row.__EMPTY, title: row.__EMPTY_1,t:"critere",parentId:currentProcessus.ref });
      }
    });

    return result;
  }

  onSubmit() {
    if (this.sheetForm.invalid) {
      this.toastService.warning('Veuillez sélectionner une norme ISO.', 'Champ Obligatoire');
      return;
    }

    if (!this.excelData) {
      this.toastService.warning('Veuillez ajouter un fichier Excel valide !', 'Fichier manquant');
      return;
    }

    this.submitted = true;

    const newSheet = {
      title: this.sheetForm.value.category.title,
      category: this.sheetForm.value.category.description,
      details: this.excelData,
    };

    this.subscriptions$.add(
      this.apiSheets.addSheet(newSheet).subscribe(
        (data) => {
          if (data.success) {
            this.toastService.success('', 'Données ajoutées avec succès.');
            this.sheetForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/sheets-list']);
            });
            this.close();
           
          } else {
            this.toastService.warning(data.message, 'Échec de l\'ajout des données');
          }
        },
        (error) => {
          this.toastService.danger('Une erreur est survenue lors de l\'ajout des données.', 'Erreur');
          console.error(error);
        }
      )
    );
  }

  close() {
    this.windowRef.close();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
