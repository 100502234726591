import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiQuizService {
  baseUrl = environment.apiEndpoint + '/quizzes';

  private quizzes$: Subject<any> = new BehaviorSubject<any>(null);

  get quizzes(): BehaviorSubject<any> {
    return this.quizzes$ as BehaviorSubject<any>;
  }

  quizEmit(value: any) {
    this.quizzes$.next(value);
  }

  updateQuizSubject(quizData) {
    if (!quizData) { return; }

    const quizArr = this.quizzes.value;

    for (const i in quizArr) {
      if (quizArr[i]._id === quizData._id) {
        quizArr[i] = quizData;
        break;
      }
    }
    this.quizEmit(quizArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addQuiz(quizData): Observable<any> {
    return this.http.post(this.baseUrl, quizData, { headers: this.auth.getHeaders() });
  }

  getquizzes(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl+'/all', { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateQuiz(quizId, quizData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + quizId, quizData, { headers: this.auth.getHeaders() });
  }
  
  deleteQuiz(quizId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + quizId, { headers: this.auth.getHeaders() });
  }
}
