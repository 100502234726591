import { Injectable } from '@angular/core';

export class Track {
  name: string;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  current: number;
  playlist: Track[] = [
    {
      name: 'Don\'t Wanna Fight',
      url: 'https://p.scdn.co/mp3-preview/6156cdbca425a894972c02fca9d76c0b70e001af'
    },
    {
      name: 'Harder',
      url: 'https://p.scdn.co/mp3-preview/92a04c7c0e96bf93a1b1b1cae7dfff1921969a7b'
    },
    {
      name: 'Come Together',
      url: 'https://p.scdn.co/mp3-preview/83090a4db6899eaca689ae35f69126dbe65d94c9'
    },
  ];
  setTracks(tracks) {
    this.playlist = [];
    if (tracks && tracks.length) {
      tracks.forEach((track, id) => {
        this.playlist.push({
          name: id,
          url: track
        });
      });
    }
  }

  playTrack(name) {
    this.current = this.playlist.findIndex((el, i, arr) => el.name === name, name);
    return this.playlist[this.current];
  }

  random(): Track {
    this.current = Math.floor(Math.random() * this.playlist.length);
    return this.playlist[this.current];
  }

  next(): Track {
    return this.getNextTrack();
  }

  prev() {
    return this.getPrevTrack();
  }

  private getNextTrack(): Track {
    if (this.current === this.playlist.length - 1) {
      this.current = 0;
    } else {
      this.current++;
    }

    return this.playlist[this.current];
  }

  private getPrevTrack(): Track {
    if (this.current === 0) {
      this.current = this.playlist.length - 1;
    } else {
      this.current--;
    }

    return this.playlist[this.current];
  }
}
