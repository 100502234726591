import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TasksService } from '../core/services/tasks.service';
import { Subscription } from 'rxjs';
import { NbAccordionComponent } from '@nebular/theme';

@Component({
  selector: 'app-task-page',
  templateUrl: './task-page.component.html',
  styleUrls: ['./task-page.component.scss']
})
export class TaskPageComponent implements OnInit, OnDestroy {
  task: any;
  loading: boolean;
  responsesWithQuestions: any[];
  images = [
    'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__340.jpg',
    'https://image.shutterstock.com/image-photo/bright-spring-view-cameo-island-260nw-1048185397.jpg'
  ];
  @ViewChild('questionList', { static: false }) accordion: NbAccordionComponent;

  private subscriptions$: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tasksService: TasksService
  ) {
  }
  ngOnInit() {
    this.getTaskFromService();
  }

  getTaskFromService() {
    this.loading = true;
    this.subscriptions$.add(this.tasksService.selectedTask.subscribe(data => {
      if (data) {
        this.task = data;
        this.initQuestions();
        this.loading = false;
      } else {
        const sub = this.tasksService
          .getSingleTask({}, this.route.snapshot.paramMap.get('id'))
          .subscribe(
            tasks => {
              if (tasks) {
                this.tasksService.selectedTaskEmit(tasks.data[0]);
              }
              sub.unsubscribe();
            }
          );
      }
    }));
  }

  initQuestions() {
    if (this.task && this.task.responses && this.task.responses.length) {
      this.responsesWithQuestions = this.task.responses.filter(response =>
        response &&
        (
          response.done || (
            response.question
            && (
              response.comment
              || (response.images && response.images.length)
              || (response.vocals && response.vocals.length))
          )
        )
      );
    }
  }

  openAll() {
    this.accordion.openAll();
  }
  closeAll() {
    console.log(this.accordion);
    this.accordion.closeAll();
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}
