import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { TasksService } from '../core/services/tasks.service';
import { Router } from '@angular/router';
import { UsersService } from '../core/services/users.service';
import { ReferentialService } from '../core/services/referential.service';

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss']
})
export class EditTaskComponent implements OnInit, OnDestroy {
  taskForm: FormGroup; submitted: boolean;
  min: Date;
  task: any;
  users: any;
  private subscriptions$ = new Subscription();
  ref: any;
  services: any;

  constructor(
    protected windowRef: NbWindowRef,
    private dateService: NbDateService<Date>,
    private tasksService: TasksService,
    private usersService: UsersService,
    private toastService: NbToastrService,
    private refService: ReferentialService
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
    this.subscriptions$.add(this.usersService.users.subscribe(data => {
      if (data) {
        this.users = data;
      }
    }));
    this.subscriptions$.add(this.refService.ref.subscribe(data => {
      if (data) {
        this.ref = data;
        this.services = data.services;
      }
    }));
  }

  private initForm() {
    if (!(this.task.dueDate && this.dateService.compareDates(new Date(this.task.dueDate), this.min) > 0)) {
      this.task.dueDate = this.dateService.addDay(this.min, 1);
    } else {
      this.task.dueDate = new Date(this.task.dueDate);
    }
    this.taskForm = new FormGroup({
      user: new FormControl(this.task.user ? this.task.user._id : null, Validators.required),
      location: new FormControl(this.task.location ? this.task.location._id : null, Validators.required),
      service: new FormControl(this.task.service ? this.task.service._id : null, Validators.required),
      dueDate: new FormControl(this.task.dueDate, Validators.required),
    });
  }

  locationSelected(location) {
    if (location) {
      location = this.ref.locations[location - 1];
      if (location.services && location.services.length) {
        this.services = location.services;
        (this.taskForm.get('service') as FormControl).reset();
      }
    }
  }

  onSubmit() {
    if (this.taskForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
    const formData = {};
    Object.keys(this.taskForm.value).forEach(key => {
      switch (key) {
        case 'dueDate':
          if (!this.task[key] || this.taskForm.get(key).value !== this.task[key]) {
            formData[key] = this.taskForm.get(key).value;
          }
          break;
        default:
          if (!this.task[key] || this.taskForm.get(key).value !== this.task[key]._id) {
            formData[key] = this.taskForm.get(key).value;
          }
          break;
      }
    });
    this.subscriptions$.add(
      this.tasksService.updateTask(this.task._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Tâche mise à jour');
            this.taskForm.reset();
            this.windowRef.close();

            this.tasksService.updateTaskSubject(data.data[0]);
          } else {
            this.toastService.warning(data.message, 'Échec de l\'edition');
          }
        }
      )
    );
  }

  handleWrongInput() {
    Object.keys(this.taskForm.controls).forEach(key => {
      if (this.taskForm.get(key).errors) {
        switch (key) {
          case 'user':
            this.toastService.warning('Selectionner qui vas faire la visite.', 'Utilisateur non selectionné');
            break;
          case 'service':
            this.toastService.warning('Saisir le service concerné.', 'Service non saisie');
            break;
          case 'location':
            this.toastService.warning('Saisir ou va etre la visite.', 'Location non saisie');
            break;
          case 'dueDate':
            this.toastService.warning('Choisir la date de la visite.', 'Date non choisie');
            break;
        }
      }
    });
  }

  changeUser(event) {
    (this.getUserControl() as FormControl).setValue(event);
  }

  getUserControl() {
    return this.taskForm.get('user');
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
