// import { Injectable } from '@angular/core';

// @Injectable({
//   providedIn: 'root'
// })
// export class ApiServicesService {

//   constructor() { }
// }
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl = environment.apiEndpoint + '/services';

  private services$: Subject<any> = new BehaviorSubject<any>(null);

  get services(): BehaviorSubject<any> {
    return this.services$ as BehaviorSubject<any>;
  }

  servicesEmit(value: any) {
    this.services$.next(value);
  }

  updateServiceSubject(serviceData) {
    if (!serviceData) { return; }

    const servicesArr = this.services.value;

    for (const i in servicesArr) {
      if (servicesArr[i]._id === serviceData._id) {
        servicesArr[i] = serviceData;
        break;
      }
    }
    this.servicesEmit(servicesArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addService(serviceData): Observable<any> {
    return this.http.post(this.baseUrl, serviceData, { headers: this.auth.getHeaders() });
  }

  getServices(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl, { params: queryParams, headers: this.auth.getHeaders() });
  }

  getAllServices(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl+"/getAllServices", { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateService(serviceId, serviceData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + serviceId, serviceData, { headers: this.auth.getHeaders() });
  }
  deleteService(serviceId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + serviceId, { headers: this.auth.getHeaders() });
  }
}
