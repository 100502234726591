import { environment } from './../environments/environment.prod';
import { TimingPipe } from './core/pipes/timing.pipe';
import { TaskVocalsWindowComponent } from './tasks-list/components/task-vocals-window/task-vocals-window.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NbDialogModule, NbAutocompleteModule } from '@nebular/theme';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng2SmartTableModule } from '@vamidicreations/ng2-smart-table';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxPrintModule } from 'ngx-print';
import { ExportAsModule } from 'ngx-export-as';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';


// Nebular
import { NbPasswordAuthStrategy, NbAuthModule, NbAuthJWTToken, NbTokenStorage, NbTokenLocalStorage } from '@nebular/auth';
// tslint:disable-next-line:max-line-length
import {
    NbThemeModule,
    NbLayoutModule,
    NbSidebarModule,
    NbAlertModule,
    NbCheckboxModule,
    NbInputModule,
    NbButtonModule,
    NbActionsModule,
    NbUserModule,
    NbTabsetModule,
    NbMenuModule,
    NbIconModule,
    NbSearchModule,
    NbContextMenuModule,
    NbWindowModule,
    NbSelectModule,
    NbCardModule,
    NbDatepickerModule,
    NbToastrModule,
    NbListModule,
    NbSpinnerModule,
    NbBadgeModule,
    NbAccordionModule,
    NbTooltipModule,
    NbTreeGridModule,
    NbProgressBarModule,
    NbRadioModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { AgGridModule } from 'ag-grid-angular';
// TEST

// Components
import { AppComponent } from './app.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { SiteLayoutComponent } from './shared/layouts/site-layout/site-layout.component';
import { AddBtnComponent } from './shared/components/add-btn/add-btn.component';
import { AddTaskComponent } from './add-task/add-task.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersListComponent } from './users-list/users-list.component';
import { TaskCardComponent } from './tasks-list/task-card/task-card.component';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { EditTaskComponent } from './edit-task/edit-task.component';
import { AppUsersListEditBtn } from './users-list/components/users-list-edit-btn.component';
import { ServicesEditBtnComponent } from './settings/services/components/services.edit.btn.component';
import { EditUserComponent } from './users-list/components/edit-user/edit-user.component';
import { AddUserComponent } from './users-list/components/add-user/add-user.component';
import { TaskImagesWindowComponent } from './tasks-list/components/task-images-window/task-images-window.component';
import { ExportWindowComponent } from './tasks-list/components/export-window/export-window.component';
import { TaskNotesWindowComponent } from './tasks-list/components/task-notes-window/task-notes-window.component';
import { TaskPageComponent } from './task-page/task-page.component';
import { CORPORATE_THEME } from './shared/styles/theme.corporate';
import { QuestionsStatsComponent } from './dashboard/components/questions-stats/questions-stats.component';
import { UsersStatsComponent } from './dashboard/components/users-stats/users-stats.component';
import { TasksStatsComponent } from './dashboard/components/tasks-stats/tasks-stats.component';
import { CalendarDueDatesComponent } from './dashboard/components/calendar-due-dates/calendar-due-dates.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { LogtoutPageComponent } from './logout-page/logout-page.component';
import { PrintTemplateComponent } from './tasks-list/components/print-template/print-template.component';
import { ExcelTemplateComponent } from './tasks-list/components/excel-template/excel-template.component';
import { SettingsComponent } from './settings/settings.component';
import { ServicesComponent } from './settings/services/services.component';
import { LocationsComponent } from './settings/locations/locations.component';
import { QuestionsComponent } from './settings/questions/questions.component';
import { AddServiceComponent } from './settings/services/components/add-service/add-service.component';
import { ModifyServiceComponent } from './settings/services/components/modify-service/modify-service.component';
import { DeleteServiceComponent } from './settings/services/components/delete-service/delete-service.component';
import { AddQuestionComponent } from './settings/questions/components/add-question/add-question.component';
import { ModifyQuestionComponent } from './settings/questions/components/modify-question/modify-question.component';
import { DeleteQuestionComponent } from './settings/questions/components/delete-question/delete-question.component';
import { QuestionsEditBtn } from './settings/questions/components/questions.edit.btn.component';
import { AddLocationComponent } from './settings/locations/components/add-location/add-location.component';
import { EditLocationComponent } from './settings/locations/components/edit-location/edit-location.component';
import { DeleteLocationComponent } from './settings/locations/components/delete-location/delete-location.component';
import { LocationsEditBtnComponent } from './settings/locations/components/locations.edit.btn.component';
import { SheetsComponent } from './settings/sheets/sheets.component';
import { AddSheetComponent } from './settings/sheets/components/add-sheet/add-sheet.component';
import { ModifySheetComponent } from './settings/sheets/components/modify-sheet/modify-sheet.component';
import { DeleteSheetComponent } from './settings/sheets/components/delete-sheet/delete-sheet.component';
import { QuizComponent } from './settings/quiz/quiz.component';
import { AddQuizComponent } from './settings/quiz/components/add/add.component';
import { ModifyComponent } from './settings/quiz/components/modify/modify.component';
import { DeleteComponent } from './settings/quiz/components/delete/delete.component';

export function momentAdapterFactory() {
    return adapterFactory(moment);
}

registerLocaleData(localeFr, 'fr');
// TEST


@NgModule({
    declarations: [
        AppComponent,
        // PIPES
        TimingPipe,
        // Components
        LoginPageComponent,
        LogtoutPageComponent,
        AuthLayoutComponent,
        SiteLayoutComponent,
        AddBtnComponent,
        AddTaskComponent,
        TasksListComponent,
        DashboardComponent,
        UsersListComponent,
        TaskCardComponent,
        EditTaskComponent,
        AppUsersListEditBtn,
        ServicesEditBtnComponent,
        EditUserComponent,
        AddUserComponent,
        TaskImagesWindowComponent,
        ExportWindowComponent,
        TaskVocalsWindowComponent,
        TaskNotesWindowComponent,
        TaskPageComponent,
        QuestionsStatsComponent,
        UsersStatsComponent,
        TasksStatsComponent,
        CalendarDueDatesComponent,
        PrintTemplateComponent,
        ExcelTemplateComponent,
        SettingsComponent,
        ServicesComponent,
        LocationsComponent,
        QuestionsComponent,
        AddServiceComponent,
        ModifyServiceComponent,
        DeleteServiceComponent,
        AddQuestionComponent,
        ModifyQuestionComponent,
        DeleteQuestionComponent,
        QuestionsEditBtn,
        AddLocationComponent,
        EditLocationComponent,
        DeleteLocationComponent,
        LocationsEditBtnComponent,
        SheetsComponent,
        AddSheetComponent,
        ModifySheetComponent,
        DeleteSheetComponent,
        QuizComponent,
        AddQuizComponent,
        ModifyComponent,
        DeleteComponent,

    ],
    // tslint:disable-next-line:max-line-length
    entryComponents: [AddTaskComponent, EditTaskComponent, AppUsersListEditBtn, AddUserComponent, EditUserComponent, ExportWindowComponent, PrintTemplateComponent, ExcelTemplateComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        FormsModule,
        // Plugins
        InfiniteScrollModule,
        AgGridModule,
        Ng2SmartTableModule,
        NbTreeGridModule,
        CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        }),
        NgxPrintModule,
        ExportAsModule,
        // Nebular
        NbLayoutModule,
        NbAutocompleteModule,
        NbEvaIconsModule,
        NbIconModule,
        NbAlertModule,
        NbInputModule,
        NbButtonModule,
        NbCheckboxModule,
        NbContextMenuModule,
        NbActionsModule,
        NbSearchModule,
        NbUserModule,
        NbSelectModule,
        NbCardModule,
        NbListModule,
        NbSpinnerModule,
        NbBadgeModule,
        NbAccordionModule,
        NbTooltipModule,
        NbTreeGridModule,
        NbAutocompleteModule,
        NbTabsetModule,
        NbProgressBarModule,
        NbRadioModule,
        NbThemeModule.forRoot({ name: 'corporate' }, [CORPORATE_THEME]),
        NbToastrModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbWindowModule.forRoot(),
        NbMenuModule.forRoot(),
        NbSidebarModule.forRoot(),
        NbDialogModule.forRoot(),
        NbAuthModule.forRoot({
            strategies: [
                NbPasswordAuthStrategy.setup({
                    name: 'email',
                    baseEndpoint: environment.apiEndpoint + '/auth/',
                    token: {
                        class: NbAuthJWTToken,
                        key: 'token'
                    },
                    login: {
                        endpoint: 'login',
                       // defaultErrors: ['La combinaison d\'Email / Mot de passe n\'est pas correcte, veuillez réessayer.'],
                       // defaultMessages: ['Vous avez été connecté avec succès.'],
                    },
                    errors: {
                        key: 'error',
                      },
                    register: false,
                    requestPass: false,
                    resetPass: false,
                    logout: {
                        endpoint: 'logout',
                        defaultErrors: ['Une erreur s\'est produite. Veuillez réessayer.'],
                        defaultMessages: ['Vous avez été déconnecté avec succès.'],
                    }
                }),
            ],
            forms: {
                validation: {
                    password: {
                        required: true,
                        minLength: 4,
                        maxLength: 50,
                    },
                    email: {
                        required: true,
                    }
                }
            },
        }),
    ],
    providers: [
        /*
        {
          provide: HTTP_INTERCEPTORS,
          multi: true,
          useClass: TokenInterceptor
        },
        {
          provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
          useValue: (req) => false // Instead of false, filter for paths that are or are not intercepeted
        },
        */
        { provide: NbTokenStorage, useClass: NbTokenLocalStorage },
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent],

})
export class AppModule { }
