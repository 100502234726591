import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { NbSidebarService, NbMenuItem } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit, OnDestroy {
  collapseIcon: boolean;
  currentUser: any;
  private subscriptions$: Subscription = new Subscription();
  items: NbMenuItem[] = [
    {
      title: 'Tableau de bord',
      link: '/dashboard',
      icon: 'home-outline',
    },
    {
      title: 'Liste des tâches',
      link: '/tasks-list',
      icon: 'clipboard-outline',
    },
    // {
    //   title: 'Liste d\'utilisateurs',
    //   link: '/users-list',
    //   icon: 'person-outline',
    // },
    {
      title: 'Settings',
      link: '/settings',
      icon: 'settings-outline',
    }
    /*,
    {
      title: 'Reponses',
      link: '/',
      icon: 'message-circle-outline',
    }*/
  ];

  userMenu = [{ title: 'Se déconnecter', url: '/#/logout' }];

  constructor(
    private auth: AuthService,
    private router: Router,
    private sidebarService: NbSidebarService,
    private userService: UsersService,
    private nbAuth: NbAuthService
  ) { }

  ngOnInit() {
    this.collapseIcon = true;
    /*
    this.subscriptions$.add(
      this.sidebarService.onToggle().subscribe(
        (collapsed) => {
          if (collapsed) {
            this.collapseIcon = !this.collapseIcon;
          }
        }
      )
    );
    */
    this.subscriptions$.add(

      this.nbAuth.getToken().subscribe(token => {
        if (token) {
          const sub = this.userService.getUsers({ ids: token.getPayload().userId }).subscribe(
            res => {
              this.currentUser = res.data[0];
              this.auth.emitCurrentUser(this.currentUser);
              sub.unsubscribe();
            },
            err => {
              console.log(err);
              sub.unsubscribe();
            }
          );
        }
      })
    );

  }

  getFullName() {
    if (this.currentUser) {
      // tslint:disable-next-line:max-line-length
      return `${this.currentUser.lastName ? this.currentUser.lastName : ''} ${this.currentUser.firstName ? this.currentUser.firstName : ''}`;
    } else {
      return 'Admin';
    }
  }

  logout(event: Event) {
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  toggleSideMenu() {
    this.sidebarService.toggle(true, 'menu-sidebar');
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
