import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSheetService {
  baseUrl = environment.apiEndpoint + '/sheets';

  private sheets$: Subject<any> = new BehaviorSubject<any>(null);

  get sheets(): BehaviorSubject<any> {
    return this.sheets$ as BehaviorSubject<any>;
  }

  sheetsEmit(value: any) {
    this.sheets$.next(value);
  }

  updateSheetsubject(sheetData) {
    if (!sheetData) { return; }

    const sheetsArr = this.sheets.value;

    for (const i in sheetsArr) {
      if (sheetsArr[i]._id === sheetData._id) {
        sheetsArr[i] = sheetData;
        break;
      }
    }
    this.sheetsEmit(sheetsArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addSheet(sheetData): Observable<any> {
    return this.http.post(this.baseUrl, sheetData, { headers: this.auth.getHeaders() });
  }

  getSheets(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl+'/all', { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateSheet(sheetId, sheetData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + sheetId, sheetData, { headers: this.auth.getHeaders() });
  }
  
  deleteSheet(sheetId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + sheetId, { headers: this.auth.getHeaders() });
  }
}
