import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  baseUrl = environment.apiEndpoint + '/tasks';

  private tasks$: Subject<any> = new BehaviorSubject<any>(null);
  private selectedTask$: Subject<any> = new BehaviorSubject<any>(null);

  get tasks(): BehaviorSubject<any> {
    return this.tasks$ as BehaviorSubject<any>;
  }

  get selectedTask(): BehaviorSubject<any> {
    return this.selectedTask$ as BehaviorSubject<any>;
  }

  tasksEmit(value: any) {
    this.tasks$.next(value);
  }

  selectedTaskEmit(value: any) {
    this.selectedTask$.next(value);
  }

  updateTaskSubject(taskData) {
    if (!taskData) { return; }

    const taskArr = this.tasks.value;

    for (const i in taskArr) {
      if (taskArr[i]._id === taskData._id) {
        taskArr[i] = taskData;
        break;
      }
    }
    this.tasksEmit(taskArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addTask(taskData): Observable<any> {
    return this.http.post(this.baseUrl, taskData, { headers: this.auth.getHeaders() });
  }

  updateTask(taskId, taskData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + taskId, taskData, { headers: this.auth.getHeaders() });
  }

  deleteTask(taskId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + taskId, { headers: this.auth.getHeaders() });
  }

  getTasks(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl, { params: queryParams, headers: this.auth.getHeaders() });
  }

  getSingleTask(queryParams: any = {}, id): Observable<any> {
    return this.http
      .get(this.baseUrl, { params: { ...queryParams, ids: id }, headers: this.auth.getHeaders() });
  }

  getExportData(queryData: any): Observable<any> {
    return this.http
      .post(this.baseUrl + '/export', queryData, { headers: this.auth.getHeaders() });
  }
}


