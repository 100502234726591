import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-sheet',
  templateUrl: './delete-sheet.component.html',
  styleUrls: ['./delete-sheet.component.scss']
})
export class DeleteSheetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
