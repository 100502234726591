import { AddUserComponent } from './components/add-user/add-user.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from './../core/services/users.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AppUsersListEditBtn } from './components/users-list-edit-btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { EditTaskComponent } from '../edit-task/edit-task.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss']
})
export class UsersListComponent implements OnInit, OnDestroy {
  nbItems; loading; page; queryParams;
  editing: boolean;
  users: Array<any> = [];
  hasMore = true;
  private subscriptions$: Subscription = new Subscription();

  settings;
  source: LocalDataSource;


  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private windowService: NbWindowService
  ) {
    this.source = new LocalDataSource(this.users);
  }

  ngOnInit() {
    this.settings = {
      /*add: {
        addButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="plus"></nb-icon>'),
        createButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="checkmark"></nb-icon>'),
        cancelButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="close"></nb-icon>'),
      },
      edit: {
        editButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="edit"></nb-icon>'),
        saveButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="checkmark"></nb-icon>'),
        cancelButtonContent: this.sanitizer.bypassSecurityTrustHtml('<nb-icon icon="close"></nb-icon>'),
      },*/
      actions: {
        delete: false,
        add: false,
        edit: false,
      },
      columns: {
        edit: {
          type: 'custom',
          renderComponent: AppUsersListEditBtn,
          filter: false,
          editable: false,
          width: '1%',
          sort: false
        },
        role: {
          title: 'Role',
          type: 'string',
        },
        email: {
          title: 'E-mail',
          type: 'string',
        },
        firstName: {
          title: 'First Name',
          type: 'string',
        },
        lastName: {
          title: 'Last Name',
          type: 'string',
        }
      }
    };
    const sub = this.usersService.getUsers(this.queryParams).subscribe(res => {
      this.users = [];
      this.users = this.users.concat(...res.data);
      this.usersService.usersEmit(this.users);
      sub.unsubscribe();
    }, err => {
      console.log(err);
      sub.unsubscribe();
    });
    this.subscriptions$.add(this.usersService.users.subscribe(data => {
      if (data) {
        this.users = data;
        this.source.load(this.users);
      }
    }));
    /*
    this.subscriptions$.add(
      this.route.queryParamMap.subscribe(params => {
        this.infiniteScrollDisabled = true;
        this.hasMore = true;
        this.page = 0;
        this.users = [];
        this.queryParams = Object.assign({}, ...params.keys.map(key => ({
          [key]: params.getAll(key).join(',') // example : if the param was 'sort=xx&sort=yy', this.queryParams will be : {sort: 'xx,yy'}
        })));
        if (this.queryParams.search) {
          this.queryParams.sort = 'search';
        }
        // this.queryParams.limit = 5;
        this.loadNext();
      })
    );
    */
  }

  loadNext() {
    if (this.loading || !this.hasMore) { return; }

    this.loading = true;
    this.queryParams.page = this.page;

    const sub = this.usersService.getUsers(this.queryParams).subscribe(res => {
      this.users = this.users.concat(...res.data);
      this.usersService.usersEmit(this.users);
      this.nbItems = res.total_count;
      this.page++;
      this.hasMore = res.has_more;
      // this.infiniteScrollDisabled = false;

      this.loading = false;
      sub.unsubscribe();

    }, err => console.log(err));

  }

  onSearch(query: string = '') {
    this.source.setFilter([
      // fields we want to include in the search
      {
        field: 'email',
        search: query
      },
      {
        field: 'firstName',
        search: query
      },
      {
        field: 'lastName',
        search: query
      }
    ], true);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  openAddUserWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(AddUserComponent, { title: 'Ajouter un utilisateur' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
