import { Component,ChangeDetectionStrategy, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  usersBtnClick(){
    this.router.navigateByUrl('/users-list');
  }

  servicesBtnClick(){
    this.router.navigateByUrl('/services-list');
  }

  locationsBtnClick(){
    this.router.navigateByUrl('/locations-list');
  }

  questionsBtnClick(){
    this.router.navigateByUrl('/questions-list');
  }
  quizzesBtnClick(){
   this.router.navigateByUrl('/quizzes-list');
  }

  sheetsBtnClick(){
   this.router.navigateByUrl('/sheets-list');
  }

}
