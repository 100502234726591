import { ApiQuestions } from '../../api-questions.service';
import { Component, OnInit, OnDestroy,ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subscription,Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import {NbDialogRef} from '@nebular/theme';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';

@Component({
selector: 'app-modify-question',
  templateUrl: './modify-question.component.html',
  styleUrls: ['./modify-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,  
})
export class ModifyQuestionComponent implements OnInit, OnDestroy {
  questionForm: FormGroup; 
  submitted: boolean;
  min: Date;
  question: any;
  services: object[]=JSON.parse(localStorage.getItem("listServices"));
  filteredControlOptions$: Observable<object[]>;
  filteredNgModelOptions$: Observable<object[]>;
  inputFormControl: FormControl;
  
  value: string;
  selectedService:any;
  listServices:any[];
  listIdServices2:any[];
  parentServices:any[];
  

  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbDialogRef<any>,
    private apiQuestion: ApiQuestions,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    
    this.initForm();
 this.getListServiceFromQuestionRow()
 
   // this.getListServices()
    /** auto-complete input */
 
   console.log("question to modify:",this.question)
   if(this.isChild()) 
   this.getListServiceFromParentQuestionRow()
   
    this.filteredControlOptions$ = of(this.services);
    this.filteredNgModelOptions$ = of(this.services);


    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
     
  }

  private initForm() {
    this.questionForm = new FormGroup({
     
      text: new FormControl(this.question.text ? this.question.text : null, Validators.required),
      
    });
  }

  //get difference between two arrays
  getDifference(a, b) {
    return a.filter(element => {
      return !b.includes(element);
    });
  }

  onSubmit() {
    if (this.questionForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true; 
        // Object.keys(this.questionForm.value).forEach(key => {
    //   if (this.questionForm.get(key).value && this.questionForm.get(key).value !== this.question[key]) {
    //     formData[key] = this.questionForm.get(key).value;
    //   }
    // });

    if(this.isChild()==false){
console.log("question:",this.question)
let details=this.question['details']
console.log("question_services =>:",this.question['services'])
console.log("services =>:",this.services)

let listIdServices1=this.listServices.map((el)=>el["_id"]);
//let newDétails=details
this.listIdServices2=listIdServices1;
// compare last list with the new to extract all new services added    and then add them to all subquestion services
//by default all services added to principal question must be added also to all subQuestions

let diff = this.getDifference(listIdServices1, this.question['services']);

console.log("listIdServices1",listIdServices1);
console.log("diff",diff);


//ajout des nouveau service au sybQuestion
for(let item of details){
  //ajout des nouveau service au sybQuestion
  let detailServices=item.services
  console.log("detailServices",detailServices)
  const concatenatedArray = diff.concat(detailServices);
   item["services"] = [...new Set(concatenatedArray)];
   console.log("newDetailServices",item["services"])
   //suppression des element se trouvant dans des subQuestion mais supprimé de parent
   
   for (let service of detailServices){
    if(!listIdServices1.includes(service))
    item["services"]=item["services"].filter((el)=>el!=service);
   }
   console.log("newDetailServices after mise à jour",item["services"])
}






    var listIdServices=this.listServices.map((el)=>el["_id"]);
    console.log("listServices_Parent:",listIdServices)
    console.log("listServices_Parent:",this.listServices)
    const formData = {
      text: this.questionForm.value.text,
      services:listIdServices,
      details
    };
console.log('formData:',formData)

    this.subscriptions$.add(
      this.apiQuestion.updateQuestion(this.question._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données de la question sont mises à jour.');
            this.questionForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/questions-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de modification!');
          }
        }
      )
    );
      }else{
        var listIdServices=this.listServices.map((el)=>el["_id"]);
        //let details=this.question.details
        console.log("listServices_Child:",listIdServices)
        console.log("listServices_brut:",this.listServices)
        console.log("parent:",this.question)
        let parentId=this.question.parentElement['_id']
        let details=this.question.parentElement['details']
        let subQuestion={
          _id:this.question._id,
         text: this.questionForm.value.text,
         services:listIdServices,
        }
        const questionUpdated = {      
          ...this.question.parentElement,     
          details:details.map((el)=>el._id==this.question._id?el=subQuestion:el)
        };
        this.subscriptions$.add(
          this.apiQuestion.updateQuestion(parentId, questionUpdated).subscribe(
            data => {
              this.submitted = false;
              if (data.success || (data.data && data.data.length > 0)) {
                this.toastService.success('', 'Les données de la question sont mises à jour.');
                //this.questionForm.reset();
                this.windowRef.close();
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                  this.router.navigateByUrl('/questions-list');
                });
              } else {
                this.toastService.warning(data.message, 'Échec de modification!');
              }
            }
          )
        );


      }
  }


  

  handleWrongInput() {
    Object.keys(this.questionForm.controls).forEach(key => {
      if (this.questionForm.get(key).errors) {
        switch (key) {
          case 'text':
            this.toastService.warning('Veuillez saisir une question!', 'Champ Obligatoir');
            break;
         
        }
      }
    });
  }

  
  private filter(value:string): object[] {
    
    const filterValue = value.toLowerCase();
        return this.isChild()==false?this.services.filter(service => (service["name"].toLowerCase().includes(filterValue))):
        this.parentServices.filter(service => (service["name"].toLowerCase().includes(filterValue)))  ;
  }

  onModelChange(value:object) {
       this.filteredNgModelOptions$ = of(this.filter(value["name"]));
  }
   
  addService(){
    
   this.listServices.unshift(this.selectedService);
        
  }

  deleteService(obj:object){
    this.listServices=this.listServices.filter((el)=>el["_id"]!=obj["_id"]);
  }

  getServiceName(obj){
    this.selectedService=obj;
    return obj.name
  }


getListServiceFromQuestionRow(): void {
 
  let arr=[]
  if(this.question["services"].length>0){
  for (const idService of this.question["services"]) {
    for (const service of this.services) {
      if(idService==service["_id"])
      arr.push(service)
    }
    
  }
}
 this.listServices=arr;

}
getListServiceFromParentQuestionRow(): void {
 
  let arr=[]
  
  if(this.question.parentElement["services"].length>0){
  for (const idService of this.question.parentElement["services"]) {
    for (const service of this.services) {
      if(idService==service["_id"])
      arr.push(service)
    }
    
  }
}
 this.parentServices=arr;
  
}

isChild(){
  if(this.question.type==="parent")
  return false
  else return true
}

close() {
  this.windowRef.close();
}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

