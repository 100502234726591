import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UsersService } from '../../../core/services/users.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit, OnDestroy {
  userForm: FormGroup; submitted: boolean;
  min: Date;
  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbWindowRef,
    private dateService: NbDateService<Date>,
    private usersService: UsersService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.userForm = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      role: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
      status: new FormControl(null, Validators.required),
    });
  }


  onSubmit() {
    if (this.userForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;

    const formData = {
      email: this.userForm.value.email,
      firstName: this.userForm.value.firstName,
      lastName: this.userForm.value.lastName,
      role: this.userForm.value.role,
      password: this.userForm.value.password,
      status: this.userForm.value.status,
    };

    this.subscriptions$.add(
      this.usersService.addUser(formData).subscribe(
        data => {
          if (data.success) {
            this.toastService.success('', 'Utilisateur ajoutée');
            this.userForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/users-list']);
            });

            this.windowRef.close();
          } else {
            this.toastService.warning(data.message, 'Echoue de l\'ajout');
          }
        }
      )
    );
  }

  handleWrongInput() {
    Object.keys(this.userForm.controls).forEach(key => {
      if (this.userForm.get(key).errors) {
        switch (key) {
          case 'email':
            this.toastService.warning('Saisir un email valide.', 'Email invaide');
            break;
          case 'firstName':
            this.toastService.warning('Saisir le prénom de l\'utilisateur.', 'Prénom non saisie');
            break;
          case 'lastName':
            this.toastService.warning('Saisir le nom de l\'utilisateur.', 'Nom non saisie');
            break;
          case 'password':
            this.toastService.warning('Saisir le mot de passe.', 'Mot de passe non saisie');
            break;
          case 'role':
            this.toastService.warning('Selectionner son role.', 'Role non selectionné');
            break;

        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

