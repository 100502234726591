import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-print-template',
    templateUrl: './print-template.component.html',
    styleUrls: ['./print-template.component.scss']
})
export class PrintTemplateComponent implements OnInit {
    today;
    @Input() printData: any;
    @Input() config: any;

    constructor() { }
    range = n => Array.from(Array(n).keys());


    ngOnInit() {
        this.today = Date.now();
    }

}
