import { TasksService } from './../../core/services/tasks.service';
import { Router } from '@angular/router';
import { TaskVocalsWindowComponent } from './../components/task-vocals-window/task-vocals-window.component';
import { TaskNotesWindowComponent } from './../components/task-notes-window/task-notes-window.component';
import { TaskImagesWindowComponent } from './../components/task-images-window/task-images-window.component';
import { Component, OnInit, Input, OnDestroy, ElementRef, ViewChild, DoCheck } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { EditTaskComponent } from 'src/app/edit-task/edit-task.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss']
})
export class TaskCardComponent implements OnInit, OnDestroy {
  private subscriptions$ = new Subscription();
  @Input() task: any;
  editing: boolean;

  responsesWithQuestions: any[] = [];
  selectedResponse: any = null;

  constructor(
    private windowService: NbWindowService,
    private router: Router,
    private tasksService: TasksService
  ) {
  }

  ngOnInit() {
    if (this.task.responses && this.task.responses.length) {
      this.responsesWithQuestions = this.task.responses.filter(response =>
        response &&
        response.done &&
        response.question
        && (
          response.comment
          || (response.images && response.images.length)
          || (response.vocals && response.vocals.length))
      );
    }
  }

  goToTaskPage() {
    this.tasksService.selectedTaskEmit(this.task);
    this.router.navigateByUrl('/tasks-list/' + this.task._id);
  }

  selectQuestion(event) {
    this.selectedResponse = event;
  }

  openWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(EditTaskComponent, { title: 'Modifier cette visite', context: { task: this.task } }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  openImages() {
    this.windowService.open(TaskImagesWindowComponent, {
      title: 'Les images',
      context: {
        images: this.selectedResponse.images,
        question: this.selectedResponse.question
      }
    });
  }
  openVocals() {
    this.windowService.open(TaskVocalsWindowComponent, {
      title: 'Les vocales',
      context: {
        vocals: this.selectedResponse.vocals,
        question: this.selectedResponse.question
      }
    });
  }
  openNotes() {
    this.windowService.open(TaskNotesWindowComponent, {
      title: 'Commentaires',
      context: {
        comment: this.selectedResponse.comment,
        question: this.selectedResponse.question
      }
    });
  }

  // deleteTask(){

  // }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
