import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-excel-template',
    templateUrl: './excel-template.component.html',
    styleUrls: ['./excel-template.component.scss']
})
export class ExcelTemplateComponent implements OnInit {

    today;
    @Input() printData: any;
    @Input() config: any;

    constructor() { }

    ngOnInit() {
        this.today = Date.now();
    }

    nbArray(nb) {
        console.log(this.config.services.length)
        if (nb >= 0) {
            return new Array(nb).fill(0);
        } else {
            return new Array(0).fill(0);
        }
    }
}
