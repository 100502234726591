import { ExportWindowComponent } from './components/export-window/export-window.component';
import { NbWindowService } from '@nebular/theme';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TasksService } from '../core/services/tasks.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss']
})
export class TasksListComponent implements OnInit, OnDestroy {
  nbItems; loading; page; queryParams;
  flipped;
  oldFlipped;
  editing: boolean;
  tasks: Array<any> = [];
  hasMore = true;
  infiniteScrollDisabled = true;
  private subscriptions$: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    private windowService: NbWindowService
  ) { }

  ngOnInit() {
    this.editing = false;

    this.subscriptions$.add(
      this.route.queryParamMap.subscribe(params => {
        this.infiniteScrollDisabled = true;
        this.hasMore = true;
        this.page = 0;
        this.tasks = [];
        this.queryParams = Object.assign({}, ...params.keys.map(key => ({
          [key]: params.getAll(key).join(',') // example : if the param was 'sort=xx&sort=yy', this.queryParams will be : {sort: 'xx,yy'}
        })));
        this.queryParams.sort = 'dueDate';

        if (this.queryParams.search) {
          this.queryParams.sort = 'search';
        }
        // this.queryParams.limit = 5;
        this.loadNext();
      })
    );
    this.subscriptions$.add(this.tasksService.tasks.subscribe(data => {
      if (data) {
        this.tasks = data;
        this.loading = false;
      }
    }));
  }

  loadNext() {
    if (this.loading || !this.hasMore) { return; }

    this.loading = true;
    this.queryParams.page = this.page;

    const sub = this.tasksService.getTasks(this.queryParams).subscribe(res => {
      this.tasks = this.tasks.concat(...res.data);
      this.tasksService.tasksEmit(res.data);
      this.nbItems = res.total_count;
      this.page++;
      this.hasMore = res.has_more;
      this.infiniteScrollDisabled = false;

      this.loading = false;
      sub.unsubscribe();

    }, err => console.log(err));

  }

  enterEditMode() {
    this.editing = true;
  }
  exitEditMode() {
    this.editing = false;
  }

  openExportWindow() {
    this.windowService.open(ExportWindowComponent, {
      title: 'Exporter ou Imprimer'
    });
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
