import { AuthService } from 'src/app/shared/services/auth.service';
import { ReferentialService } from './../core/services/referential.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TasksService } from '../core/services/tasks.service';
import { Router } from '@angular/router';
import { UsersService } from '../core/services/users.service';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-task.component.html',
  styleUrls: ['./add-task.component.scss']
})
export class AddTaskComponent implements OnInit, OnDestroy {
  taskForm: FormGroup; submitted: boolean;
  min: Date;
  private subscriptions$ = new Subscription();
  users: any;
  ref: any;
  services: any;

  constructor(
    protected windowRef: NbWindowRef,
    private dateService: NbDateService<Date>,
    private auth: AuthService,
    private tasksService: TasksService,
    private usersService: UsersService,
    private toastService: NbToastrService,
    private refService: ReferentialService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
    this.subscriptions$.add(this.usersService.users.subscribe(data => {
      if (data) {
        this.users = data;
      }
    }));
    this.subscriptions$.add(this.refService.ref.subscribe(data => {
      if (data) {
        this.ref = data;
        this.services = data.services;
      }
    }));
  }

  private initForm() {
    this.taskForm = new FormGroup({
      user: new FormControl(null, Validators.required),
      service: new FormControl(null, Validators.required),
      location: new FormControl(null, Validators.required),
      dueDate: new FormControl(null, Validators.required),
    });
  }

  locationSelected(location) {
    if (location.services && location.services.length) {
      this.services = location.services;
      (this.taskForm.get('service') as FormControl).reset();
    }
  }

  onSubmit() {
    if (this.taskForm.invalid) {
      return;
    }
    this.submitted = true;

    const formData = {
      user: this.taskForm.value.user,
      issuer: this.auth.getToken(), // Get the current user ID
      service: this.taskForm.value.service,
      location: this.taskForm.value.location._id,
      dueDate: this.taskForm.value.dueDate
    };

    this.subscriptions$.add(
      this.tasksService.addTask(formData).subscribe(
        data => {
          if (data.success) {
            this.toastService.success('', 'Tâche ajoutée');
            this.taskForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/tasks-list');
            });
            this.windowRef.close();
          } else {
            this.toastService.warning(data.message, 'L\'ajout est échouée');
          }
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
