import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReferentialService {
  baseUrl = environment.apiEndpoint + '/ref';

  private ref$: Subject<any> = new BehaviorSubject<any>(null);

  get ref(): BehaviorSubject<any> {
    return this.ref$ as BehaviorSubject<any>;
  }

  refEmit(value: any) {
    this.ref$.next(value);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  getRef(): Observable<any> {
    return this.http.get(this.baseUrl, { headers: this.auth.getHeaders() });
  }
}
