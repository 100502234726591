import { ReferentialService } from './../core/services/referential.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { NbLoginComponent, NbAuthResult, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends NbLoginComponent {

  constructor(
    protected service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected router: Router,
    private customAuthService: AuthService,
    private refService: ReferentialService
  ) {
    super(service, options, cd, router);
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;

      if (result.isSuccess()) {
        this.messages = result.getMessages();
      } else {
        this.errors = result.getErrors();
      }
      if (result.getToken()) {
        this.customAuthService.setToken(result.getToken().getValue());
      }
      const redirect = result.getRedirect();
      if (redirect) {
        const sub2 = this.refService.getRef().subscribe(res => {
            this.refService.refEmit(res);
            sub2.unsubscribe();
            this.router.navigateByUrl(redirect);
          }, err => {
            console.log(err);
            sub2.unsubscribe();
          });
      }
      this.cd.detectChanges();
    });
  }
}
