import { Component, Input, NgModule, OnDestroy,TemplateRef } from '@angular/core';
import { ViewCell } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { ModifyServiceComponent} from './modify-service/modify-service.component';
import { DeleteServiceComponent} from './delete-service/delete-service.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-services',
  // tslint:disable-next-line:max-line-length
  template: `<span class="d-flex full-container"><button nbTooltip="Modifier ce service" (click)="openEditWindow()" nbButton ghost [status]="editing ? 'info' : 'danger'"><nb-icon icon="edit"></nb-icon></button><button class="ml-1" nbTooltip="Supprimer ce service" (click)="open(false)" nbButton ghost [status]="danger"><nb-icon icon="trash-2-outline"></nb-icon></button></span>`,
})


export class ServicesEditBtnComponent implements ViewCell, OnDestroy {
  renderValue: string;
  editing = false;
  private subscriptions$ = new Subscription();
  
  @Input() value: string | number;
  @Input() rowData: any;

  constructor(
    private windowService: NbWindowService,private dialogService: NbDialogService) { }

    //modal delete alert
    private dialogRef:NbDialogRef<any>;
    // openWithBackdropClick() {
    //   this.open(true);
    // }
  
    // openWithoutBackdropClick() {
    //   this.open(false);
    // }
  
  
     open(closeOnBackdropClick: boolean) {
      this.dialogRef= this.dialogService.open(DeleteServiceComponent, {context: {service: this.rowData },closeOnBackdropClick:closeOnBackdropClick});
    }
  //end modal delete alert

  openEditWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(ModifyServiceComponent, {
        title: 'Modifier ce service',
        context: { service: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  openDeleteWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(ModifyServiceComponent, {
        title: 'Supprimer ce service',
        context: { user: this.rowData }
      }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}
