import { ApiLocationsService } from '../../api-locations.service';
import { Component, OnInit, OnDestroy,ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidationErrors } from '@angular/forms';
import { Subscription,Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class  EditLocationComponent implements OnInit, OnDestroy {
  locationForm: FormGroup; 
  submitted: boolean;
  min: Date;
  location: any;
  services2: object[];
  filteredControlOptions2$: Observable<object[]>;
  filteredNgModelOptions2$: Observable<object[]>;
  inputFormControl2: FormControl;
  value: string;
  selectedService2:any;
  listServices2:object[]=[];

  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbWindowRef,
    private apiLocation: ApiLocationsService,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
  }

  ngOnInit() {
   
    this.initForm();

    this.getListServices2()
    /** auto-complete input */
  this.getListServiceFromLocationRow2()
  console.log("location to modify:",this.location)
    this.filteredControlOptions2$ = of(this.services2);
    this.filteredNgModelOptions2$ = of(this.services2);


    this.inputFormControl2 = new FormControl();
    this.filteredControlOptions2$ = this.inputFormControl2.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
  }

  private initForm() {
    this.locationForm = new FormGroup({
     
      name: new FormControl(this.location.name ? this.location.name : null, Validators.required),
      
    });
  }

  onSubmit() {
    if (this.locationForm.invalid) {
      this.handleWrongInput();
      return;
    }
    if(this.listServices2.length<1){
      this.toastService.warning('Veuillez ajouter un service!', 'Valeurs Obligatoires');
      return;
    }
    this.submitted = true;
        // Object.keys(this.locationForm.value).forEach(key => {
    //   if (this.locationForm.get(key).value && this.locationForm.get(key).value !== this.location[key]) {
    //     formData[key] = this.locationForm.get(key).value;
    //   }
    // });
    var listIdServices=this.listServices2.map((el)=>el["_id"]);
    const formData = {
      name: this.locationForm.value.name,
      services:listIdServices
    };
console.log('formDataEdit:',formData)

    this.subscriptions$.add(
      this.apiLocation.updateLocation(this.location._id, formData).subscribe(
        data => {
          this.submitted = false;
          if (data.success || (data.data && data.data.length > 0)) {
            this.toastService.success('', 'Les données du lieu sont mises à jour.');
            this.locationForm.reset();
            this.windowRef.close();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigateByUrl('/locations-list');
            });
          } else {
            this.toastService.warning(data.message, 'Échec de modification!');
          }
        }
      )
    );
  }

  getListServices2():void{
    const sub = this.apiService.getAllServices().subscribe(res => {
      this.services2 = [];
      this.services2 = this.services2.concat(...res.data);
      console.log("res:"+res)
      this.apiService.servicesEmit(this.services2);
      sub.unsubscribe();
    }, err => {
      console.log(err);
      sub.unsubscribe();
    });
    this.subscriptions$.add(this.apiService.services.subscribe(data => {
      if (data) {
        console.log("data:",data)
        this.services2 = data;
       
       
      }
    }));
  }

  handleWrongInput() {
    Object.keys(this.locationForm.controls).forEach(key => {
      if (this.locationForm.get(key).errors) {
        switch (key) {
          case 'name':
            this.toastService.warning('Veuillez saisir un lieu!', 'Champ Obligatoir');
            break;
         
        }
      }
    });
  }

  
  private filter(value:string): object[] {
    const filterValue = value.toLowerCase();
        return this.services2.filter(service => (service["name"].toLowerCase().includes(filterValue)));
  }

  onModelChange(value:object) {
       this.filteredNgModelOptions2$ = of(this.filter(value["name"]));
  }
   
  addService2(){
    this.listServices2.unshift(this.selectedService2);
    console.log("listServices2:",this.listServices2)
    console.log("listServices2:",this.listServices2.map((el)=>el["_id"]))
  }

  deleteService2(obj:object){
    this.listServices2=this.listServices2.filter((el)=>el["_id"]!=obj["_id"]);
  }

  getServiceName2(obj){
    this.selectedService2=obj;
    console.log("objSelected:",obj)
    return obj.name
  }


getListServiceFromLocationRow2(){
  let arr=[]
  if(this.location["services"].length>0){
  for (const idService of this.location["services"]) {
    for (const service of this.services2) {
      if(idService==service["_id"])
      arr.push(service)
    }
    
  }
}
// var questionToModify= {name:this.location["name"],services:arr};
 this.listServices2=arr;
 //console.log('questionToModify:',questionToModify);
}

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

