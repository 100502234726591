import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token = null;
  // tslint:disable-next-line:variable-name
  private _currentUser: Subject<any> = new BehaviorSubject<any>(null);

  get currentUser() {
    return this._currentUser.asObservable();
  }
  emitCurrentUser(data) {
    this._currentUser.next(data);
  }

  constructor() { }

  getHeaders() {
    return new HttpHeaders().set('Authorization', 'Bearer ' + this.getToken());
    /* let token;
    this.getToken().then(nbToken => { token = nbToken.getValue(); }, err => null);
    return token ? new HttpHeaders().set('Authorization', 'Bearer ' + token) : null; */
  }

  setToken(token: string) {
    this.token = token;
  }

  getToken(): string {
    const authAppToken = localStorage.getItem('auth_app_token');
    if (authAppToken) {
      return JSON.parse(authAppToken).value;
    } else {
      return null;
    }
  }

  getPayload() {
    const authAppToken = localStorage.getItem('auth_app_token');
    if (authAppToken) {
      return JSON.parse(atob(authAppToken.split('.')[1]));
    } else {
      return null;
    }
  }

  isAuthenticated(): boolean {
    return !!this.token;
  }

  logout() {
    if (this.getToken()) {
  
      localStorage.removeItem('auth_app_token');
      this.setToken(null);
    }
  }
}
