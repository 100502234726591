import { Component, OnInit ,} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiLocationsService } from '../../api-locations.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-delete-location',
  templateUrl: './delete-location.component.html',
  styleUrls: ['./delete-location.component.scss']
})
export class DeleteLocationComponent implements OnInit {
  private subscriptions$ = new Subscription();
  loading: boolean;
  name:string;
  location:any;
  constructor(
    protected dialogRefLocation: NbDialogRef<any>, 
    private apiLocation: ApiLocationsService,
    private toastService: NbToastrService,
    private router: Router) { }

  ngOnInit(): void {
this.name=this.location.name;
  }
  close() {
    this.dialogRefLocation.close();
  }

deleteLocation(){
  this.loading=true;
  this.subscriptions$.add(this.apiLocation.deleteQuestion(this.location._id).subscribe(
    data => {
      this.loading = false;
      if (data.success) {
        this.dialogRefLocation.close();
        this.toastService.success('', 'Le lieu a été supprimé avec succès');
       
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/locations-list');
        });
      } else {
        this.toastService.warning(data.message, 'Échec de la suppression du lieu!');
      }
    }
  )
);
}



}
