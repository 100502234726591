// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-add-service',
//   templateUrl: './add-service.component.html',
//   styleUrls: ['./add-service.component.scss']
// })
// export class AddServiceComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from '../../api-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit, OnDestroy {
  serviceForm: FormGroup; submitted: boolean;
  min: Date;
  private subscriptions$ = new Subscription();

  constructor(
    protected windowRef: NbWindowRef,
    private dateService: NbDateService<Date>,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.serviceForm = new FormGroup({
     
      name: new FormControl(null, Validators.required),
     
    });
  }


  onSubmit() {
    if (this.serviceForm.invalid) {
      this.handleWrongInput();
      return;
    }
    this.submitted = true;
    var dataLength = localStorage.getItem("dataLength");
    const formData = {
      _id:parseInt(dataLength)+1,
      name: this.serviceForm.value.name,
     
    };

    this.subscriptions$.add(
      this.apiService.addService(formData).subscribe(
        data => {
          if (data.success) {
            this.toastService.success('', 'Service ajouté');
            this.serviceForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/services-list']);
            });

            this.windowRef.close();
          } else {
            this.toastService.warning(data.message, 'Echoue de l\'ajout');
          }
        }
      )
    );
  }

  handleWrongInput() {
    Object.keys(this.serviceForm.controls).forEach(key => {
      if (this.serviceForm.get(key).errors) {
        switch (key) {
          case 'name':
            this.toastService.warning('Veuillez saisir un nom', 'Name required!');
            break;
          
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}

