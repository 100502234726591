import { Component,ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { NbWindowRef, NbDateService, NbToastrService} from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription,Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiLocationsService } from '../../api-locations.service';
import { ApiService } from '../../../services/api-services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddLocationComponent implements OnInit, OnDestroy {
  locationForm: FormGroup; submitted: boolean;
  min: Date;
  private subscriptions$ = new Subscription();
  /** */
  services: object[];
  filteredControlOptions$: Observable<object[]>;
  filteredNgModelOptions$: Observable<object[]>;
  inputFormControl: FormControl;
  value: string;
  selectedService;
  listServices:object[]=[];
  
  

  /** */
  constructor(
    protected windowLocation: NbWindowRef,
    private dateService: NbDateService<Date>,
    private apiLocation: ApiLocationsService,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
  }

  ngOnInit() {
    this.initForm();
    this.getListServices()
    /** auto-complete input */
    this.filteredControlOptions$ = of(this.services);
    this.filteredNgModelOptions$ = of(this.services);

    this.inputFormControl = new FormControl();
    this.filteredControlOptions$ = this.inputFormControl.valueChanges
      .pipe(
        startWith(''),
        map(filterString => this.filter(filterString)),
      );
    /** end */
    
  }

  private initForm() {
    this.locationForm = new FormGroup({
     
      name: new FormControl(null, Validators.required),
     
    });
  }


  
  onSubmit() {
    if (this.locationForm.invalid) {
      this.handleWrongInput();
      return;
    }
    if(this.listServices.length<1){
      this.toastService.warning('Veuillez ajouter un service!', 'Valeurs Obligatoires');
      return;
    }
    this.submitted = true;
    var dataLength = localStorage.getItem("dataLocLength");//to increment id
    var listIdServices=this.listServices.map((el)=>el["_id"]);
    const formData = {
      _id:parseInt(dataLength)+1,
      name: this.locationForm.value.name,
      services:listIdServices
     
    };

    this.subscriptions$.add(
      this.apiLocation.addLocation(formData).subscribe(
        data => {
          if (data.success) {
            this.toastService.success('', 'Lieu ajouté');
            this.locationForm.reset();
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/locations-list']);
            });

            this.windowLocation.close();
          } else {
            this.toastService.warning(data.message, 'Echoue de l\'ajout');
          }
        }
      )
    );
  }


getListServices():void{
  const sub = this.apiService.getAllServices().subscribe(res => {
    this.services = [];
    this.services = this.services.concat(...res.data);
    console.log("res:"+res)
    this.apiService.servicesEmit(this.services);
    sub.unsubscribe();
  }, err => {
    console.log(err);
    sub.unsubscribe();
  });
  this.subscriptions$.add(this.apiService.services.subscribe(data => {
    if (data) {
      console.log("data serv loc:",data)
      this.services = data;
     
    //  localStorage.setItem("dataLength",data.length)
    //   console.log("data services:",this.services)
    //   //this.loading = false;
    }
  }));
}


  handleWrongInput() {
    Object.keys(this.locationForm.controls).forEach(key => {
      if (this.locationForm.get(key).errors) {
        switch (key) {
          case 'name':
            this.toastService.warning('Veuillez saisir un lieu!', 'Champ Obligatoir');
            break;
          
        }
      }
    });
  }


  private filter(value:string): object[] {
    const filterValue = value.toLowerCase();
        return this.services.filter(service => (service["name"].toLowerCase().includes(filterValue)));
  }

  onModelChange(value:object) {
       this.filteredNgModelOptions$ = of(this.filter(value["name"]));
  }
   
  addService(){
   this.listServices.unshift(this.selectedService);
    console.log("listServices:",this.listServices)
    console.log("listServices:",this.listServices.map((el)=>el["_id"]))
       
  }
  deleteService(obj:object){
    this.listServices=this.listServices.filter((el)=>el["_id"]!=obj["_id"]);
  }

  getServiceName(obj){
    this.selectedService=obj;
    return obj.name
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  } 
}

