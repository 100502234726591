// import { Component, OnInit ,Input} from '@angular/core';

// import { NbSortDirection, NbSortRequest, NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';

// interface TreeNode<T> {
//   data: T;
//   children?: TreeNode<T>[];
//   expanded?: boolean;
// }

// interface FSEntry {
//   name: string;
//   size: string;
//   kind: string;
//   items?: number;
// }

// // @Component({
// //   selector: 'nb-tree-grid-showcase',
// //   templateUrl: './tree-grid-showcase.component.html',
// //   styleUrls: ['./tree-grid-shared.scss', './tree-grid-showcase.component.scss'],
// // })
// @Component({
//   selector: 'app-services',
//   templateUrl: './services.component.html',
//   styleUrls: ['./services.component.scss']
// })
// export class ServicesComponent {
//   customColumn = 'name';
//   defaultColumns = [ 'size', 'kind', 'items','actions' ];
//   allColumns = [ this.customColumn, ...this.defaultColumns ];

//   dataSource: NbTreeGridDataSource<FSEntry>;

//   sortColumn: string;
//   sortDirection: NbSortDirection = NbSortDirection.NONE;

//   constructor(private dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>) {
//     this.dataSource = this.dataSourceBuilder.create(this.data);
//   }

//   updateSort(sortRequest: NbSortRequest): void {
//     this.sortColumn = sortRequest.column;
//     this.sortDirection = sortRequest.direction;
//   }

//   getSortDirection(column: string): NbSortDirection {
//     if (this.sortColumn === column) {
//       return this.sortDirection;
//     }
//     return NbSortDirection.NONE;
//   }

//   private data: TreeNode<FSEntry>[] = [
//     {
//       data: { name: 'Projects', size: '1.8 MB', items: 5, kind: 'dir',actions:'Update' },
//       children: [
//         { data: { name: 'project-1.doc', kind: 'doc', size: '240 KB' } },
//         { data: { name: 'project-2.doc', kind: 'doc', size: '290 KB' } },
//         {
//           data: { name: 'project-3', kind: 'dir', size: '466 KB', items: 3 },
//           children: [
//             { data: { name: 'project-3A.doc', kind: 'doc', size: '200 KB' } },
//             { data: { name: 'project-3B.doc', kind: 'doc', size: '266 KB' } },
//             { data: { name: 'project-3C.doc', kind: 'doc', size: '0' } },
//           ],
//         },
//         { data: { name: 'project-4.docx', kind: 'docx', size: '900 KB' } },
//       ],
//     },
//     {
//       data: { name: 'Reports', kind: 'dir', size: '400 KB', items: 2 },
//       children: [
//         {
//           data: { name: 'Report 1', kind: 'dir', size: '100 KB', items: 1 },
//           children: [
//             { data: { name: 'report-1.doc', kind: 'doc', size: '100 KB' } },
//           ],
//         },
//         {
//           data: { name: 'Report 2', kind: 'dir', size: '300 KB', items: 2 },
//           children: [
//             { data: { name: 'report-2.doc', kind: 'doc', size: '290 KB' } },
//             { data: { name: 'report-2-note.txt', kind: 'txt', size: '10 KB' } },
//           ],
//         },
//       ],
//     },
//     {
//       data: { name: 'Other', kind: 'dir', size: '109 MB', items: 2 },
//       children: [
//         { data: { name: 'backup.bkp', kind: 'bkp', size: '107 MB' } },
//         { data: { name: 'secret-note.txt', kind: 'txt', size: '2 MB' } },
//       ],
//     },
//   ];

//   getShowOn(index: number) {
//     const minWithForMultipleColumns = 400;
//     const nextColumnStep = 100;
//     return minWithForMultipleColumns + (nextColumnStep * index);
//   }
// }

// @Component({
//   selector: 'nb-fs-icon',
//   template: `
//     <nb-tree-grid-row-toggle [expanded]="expanded" *ngIf="isDir(); else fileIcon">
//     </nb-tree-grid-row-toggle>
//     <ng-template #fileIcon>
//       <nb-icon icon="file-text-outline"></nb-icon>
//     </ng-template>
//   `,
// })
// export class FsIconComponent {
//   @Input() kind: string;
//   @Input() expanded: boolean;

//   isDir(): boolean {
//     return this.kind === 'dir';
//   }
// }
import { AddServiceComponent } from './components/add-service/add-service.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from './api-services.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ServicesEditBtnComponent } from './components/services.edit.btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {
  nbItems; loading; page; queryParams;
  editing: boolean;
  services: Array<any> = [];
  hasMore = true;
  private subscriptions$: Subscription = new Subscription();

  settings;
  source: LocalDataSource;


  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private windowService: NbWindowService
  ) {
    this.source = new LocalDataSource(this.services);
  }

  ngOnInit() {
    this.settings = {
      actions: {
        delete: false,
        add: false,
        edit: false,
      },
      columns: {
        
        _id: {
          title: 'Id',
          type: 'number',
          
        },
        name: {
          title: 'Nom du service',
          type: 'string',
        },
        edit: {
          title:"Actions",
          type: 'custom',
          renderComponent: ServicesEditBtnComponent,
          filter: false,
          editable: false,
          width: '1%',
          sort: false
        },
      }
      
    };

    const sub = this.apiService.getServices(this.queryParams).subscribe(res => {
      this.services = [];
      this.services = this.services.concat(...res.data);
      this.apiService.servicesEmit(this.services);
      sub.unsubscribe();
    }, err => {
      console.log(err);
      sub.unsubscribe();
    });
    this.subscriptions$.add(this.apiService.services.subscribe(data => {
      if (data) {
        console.log("data:",data)
        this.services = data;
        this.source.load(this.services);
       localStorage.setItem("dataLength",data.length)
        console.log("source:",this.source)
        this.loading = false;
      }
    }));
    /*
    this.subscriptions$.add(
      this.route.queryParamMap.subscribe(params => {
        this.infiniteScrollDisabled = true;
        this.hasMore = true;
        this.page = 0;
        this.services = [];
        this.queryParams = Object.assign({}, ...params.keys.map(key => ({
          [key]: params.getAll(key).join(',') // example : if the param was 'sort=xx&sort=yy', this.queryParams will be : {sort: 'xx,yy'}
        })));
        if (this.queryParams.search) {
          this.queryParams.sort = 'search';
        }
        // this.queryParams.limit = 5;
        this.loadNext();
      })
    );
    */
    
  }

  

  loadNext() {
    if (this.loading || !this.hasMore) { return; }

    this.loading = true;
    this.queryParams.page = this.page;

    const sub = this.apiService.getServices(this.queryParams).subscribe(res => {
      this.services = this.services.concat(...res.data);
      this.apiService.servicesEmit(this.services);
      this.nbItems = res.total_count;
      this.page++;
      this.hasMore = res.has_more;
      // this.infiniteScrollDisabled = false;
      this.loading = false;
      sub.unsubscribe();

    }, err => console.log(err));

  }

  onSearch(query: string = '') {
    this.source.setFilter([
      // fields we want to include in the search
      {
        field: 'name',
        search: query
      }
    ], true);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  openAddUserWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowService.open(AddServiceComponent, { title: 'Ajouter un service' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }

 
  
  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
