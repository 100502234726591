
import { Component, OnInit ,} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiQuestions } from '../../api-questions.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-delete-question',
  templateUrl: './delete-question.component.html',
  styleUrls: ['./delete-question.component.scss']
})
export class DeleteQuestionComponent implements OnInit {
  private subscriptions$ = new Subscription();
  loading: boolean;
  text:string;
  question:any;
  constructor(
    protected dialogRefQuestion: NbDialogRef<any>, 
    private apiQuestion: ApiQuestions,
    private toastService: NbToastrService,
    private router: Router) { }

  ngOnInit(): void {
   this.text=this.question['text'];
  }
  close() {
    this.dialogRefQuestion.close();
  }

deleteQuestion(){
  this.loading=true;
 if(this.isChild()==false){
  this.subscriptions$.add(this.apiQuestion.deleteQuestion(this.question._id).subscribe(
    data => {
      this.loading = false;
      if (data.success) {
        this.dialogRefQuestion.close();
        this.toastService.success('', 'La question a été supprimée avec succès');
       
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/questions-list');
        });
      } else {
        this.toastService.warning(data.message, 'Échec de la suppression de la question!');
      }
    }
  )
);
  }else{
//else (child) update details question

let parentId=this.question.parentElement['_id']
let details=this.question.parentElement['details']
console.log(" details", details)
//delete sub-question from details
let newDetailsList= details.filter((el)=>el._id!==this.question._id)
console.log("newDetailsList",newDetailsList)

let questionUpdated={...this.question.parentElement,details:newDetailsList}
console.log("questionUpdated",questionUpdated)

this.subscriptions$.add(
  this.apiQuestion.updateQuestion(parentId, questionUpdated).subscribe(
    data => {
      this.loading = false;
      if (data.success || (data.data && data.data.length > 0)) {
        this.toastService.success('', 'Les données de la question sont mises à jour.');
        //this.questionForm.reset();
        this.dialogRefQuestion.close();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/questions-list');
        });
      } else {
        this.toastService.warning(data.message, 'Échec de modification!');
      }
    }
  )
);
  }

}

isChild(){
  if(this.question.type==="parent")
  return false
  else return true
}

}
