import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { NbDialogRef, NbDateService, NbToastrService } from "@nebular/theme";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ApiQuizService } from "../../api-quiz.service";
import { ApiSheetService } from "../../../sheets/api-sheet.service";
import { ApiService } from "../../../services/api-services.service";
import { Router } from "@angular/router";
import {
  NbTreeGridDataSource,
  NbTreeGridDataSourceBuilder,
} from "@nebular/theme";
import * as XLSX from "xlsx";
import { forEachChild } from "typescript";
import { AriaDescriber } from "@angular/cdk/a11y";

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  _id: string;
  text: string;
  type: string;
  isSelected: boolean;
}

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddQuizComponent implements OnInit, OnDestroy {
  queryParams: any;
  nbItems: number;
  loading: boolean;
  page: number;
  quizForm: FormGroup;
  submitted = false;
  min: Date;
  quiz: any;
  quizData: any;
  sheets: TreeNode<FSEntry>[] = [];
  locationOptions = [];
  subscriptions$ = new Subscription();
  dataSource: NbTreeGridDataSource<FSEntry>;
  selectedRows: any[] = []; // Array to store all selected rows
  hasMore = true;

  expanded: boolean;
  customColumn = "Réf";
  optionColumn = "Options";
  defaultColumns = ["text"];
  allColumns = [this.customColumn, ...this.defaultColumns, this.optionColumn];
  isoOptions = [];

  constructor(
    dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    protected windowRef: NbDialogRef<any>,
    private dateService: NbDateService<Date>,
    private apiQuiz: ApiQuizService,
    private apiSheets: ApiSheetService,
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router
  ) {
    this.min = this.dateService.today();
    this.dataSource = dataSourceBuilder.create(this.sheets);
  }

  ngOnInit() {
    this.initForm();

    const sub = this.apiSheets.getSheets(this.queryParams).subscribe(
      (res) => {
        this.sheets = [];
        console.log("res:", res);
        this.sheets = this.sheets.concat(...res.Sheets);
        this.apiSheets.sheetsEmit(this.sheets);
        sub.unsubscribe();
      },
      (err) => {
        console.log(err);
        sub.unsubscribe();
      }
    );

    this.subscriptions$.add(
      this.apiSheets.sheets.subscribe((data) => {
        if (data) {
          console.log("data--------------:", data);

          const newList =
            data && data.length > 0
              ? data.map((file) => ({
                  data: {
                    _id: file.title,
                    text: file.category,
                    type: "parent",
                    t: "file",
                    isSelected: false,
                  },
                  children: file.details.map((article) => ({
                    data: {
                      _id: article.ref,
                      text: article.title,
                      type: "child",
                      t: "article",
                      isSelected: false,
                    },
                    children: article.processus.map((item) => ({
                      data: {
                        _id: item.ref,
                        text: item.title,
                        type: "child",
                        t: "processus",
                        isSelected: false,
                      },
                      children:
                        item.criteres &&
                        item.criteres.map((critere) => ({
                          data: {
                            _id: critere.ref,
                            text: critere.title,
                            type: "child",
                            t: "critere",
                            parentId: item.ref,
                            isSelected: false,
                          },
                        })),
                    })),
                  })),
                }))
              : console.log("liste vide!");

          console.log("newList:", newList);
          this.sheets = newList;
          this.dataSource.setData(this.sheets);
          localStorage.setItem("dataQLength", data.length.toString());
          this.loading = false;
        }
      })
    );

    this.loadIsoOptions();
  }

  private initForm() {
    this.quizForm = new FormGroup({
      title: new FormControl(null, Validators.required),
      category: new FormControl(null, Validators.required),
    });
  }

  private loadLocationOptions() {
    // get list of locations
  }

  private loadIsoOptions() {
    this.isoOptions = [
      { title: "Mixed", description: "Mixed. " },
      { title: "ISO 9001", description: "Quality management systems. " },
      { title: "ISO 14001", description: "Environmental management systems." },
      {
        title: "ISO 45001",
        description: "Occupational health and safety management systems.",
      },
      {
        title: "ISO 27001",
        description: "Information security management systems.",
      },
      { title: "ISO 22000", description: "Food safety management systems." },
      { title: "ISO 50001", description: "Energy management systems." },
      { title: "ISO 31000", description: "Risk management" },
      { title: "ISO 26000", description: "Social responsibility." },
      {
        title: "ISO 22301",
        description: "Business continuity management systems.",
      },
      {
        title: "ISO 13485",
        description: "Quality management systems for medical devices.",
      },
      { title: "ISO 10002", description: "Quality management." },
      {
        title: "ISO 19011",
        description: "Guidelines for auditing management systems.",
      },
      {
        title: "ISO 17025",
        description: "Testing and calibration laboratories.",
      },
      { title: "ISO 15189", description: "Medical laboratories." },
      {
        title: "ISO 20121",
        description: "Event sustainability management systems.",
      },
    ];
    this.quizForm.value.category = null;
  }

  onSubmit() {
    console.log("selected rows:", this.selectedRows);

    if (this.quizForm.invalid) {
      this.toastService.warning(
        "Veuillez remplir tous les champs obligatoires.",
        "Champ Obligatoire"
      );
      return;
    }

    this.submitted = true;

    const newQuiz = {
      title: this.quizForm.value.title,
      category: this.quizForm.value.category.description,
      details: this.selectedRows,
    };

    console.log("nselectedRowss==>:", this.selectedRows);
    let newList = [];
    for (let el of this.selectedRows) {
      // reformatter  la liste
    }

    this.subscriptions$.add(
      this.apiQuiz.addQuiz(newQuiz).subscribe(
        (data) => {
          if (data.success) {
            this.toastService.success("", "Données ajoutées avec succès.");
            this.quizForm.reset();
            this.router
              .navigateByUrl("/", { skipLocationChange: true })
              .then(() => {
                this.router.navigate(["/quizzes-list"]);
              });
            this.close();
          } else {
            this.toastService.warning(
              data.message,
              "Échec de l'ajout des données"
            );
          }
        },
        (error) => {
          this.toastService.danger(
            "Une erreur est survenue lors de l'ajout des données.",
            "Erreur"
          );
          console.error(error);
        }
      )
    );
  }

  close() {
    this.windowRef.close();
  }

  // Fonction pour gérer la sélection des lignes et créer les processus avec leurs détails
 
  onCheckboxChange(row: any) {
    row.data.isSelected = !row.data.isSelected;

    if (row.data.isSelected) {
      // Ajouter le processus ou critère sélectionné
      this.addToSelectedRows(row);
      if (this.hasChildren(row)) {
        this.updateChildSelection(row, true);
      }
    } else {
      // Retirer le processus ou critère désélectionné
      this.removeFromSelectedRows(row);
      if (this.hasChildren(row)) {
        this.updateChildSelection(row, false);
      }
    }

    console.log("Selected Rows:", this.selectedRows);
  }

  private hasChildren(row: any): boolean {
    return Array.isArray(row.children) && row.children.length > 0;
  }

  private updateChildSelection(row: any, isSelected: boolean) {
    if (this.hasChildren(row)) {
      row.children.forEach((child: any) => {
        child.data.isSelected = isSelected;

        if (isSelected) {
          this.addToSelectedRows(child);
        } else {
          this.removeFromSelectedRows(child);
        }

        if (this.hasChildren(child)) {
          this.updateChildSelection(child, isSelected);
        }
      });
    }
  }

  private addToSelectedRows(row: any) {
    console.log("selectedRow--->",row)
    if (row.data.t==="processus") {
      // Cas d'un processus principal
      const formattedRow = this.formatRow(row);
      const existingIndex = this.selectedRows.findIndex(
        (node) => node.ref === formattedRow.ref 
      );

      if (existingIndex === -1) {
        this.selectedRows.push(formattedRow);
      } else {
        // Mettre à jour les détails s'il y a des enfants
        if (this.hasChildren(row)) {
          formattedRow.details = this.mergeDetails(
            this.selectedRows[existingIndex].details,
            row.children.map((child: any) => this.formatRow(child))
          );
          this.selectedRows[existingIndex] = formattedRow;
        }
      }
    } else {
      // Cas d'un critère (avec un parent processus)
      const parentRef = row.data.parentId;
      const parentNode = this.selectedRows.find(
        (node) => node.ref === parentRef
      );

      if (!parentNode) {
        // Ajouter le parent s'il n'existe pas encore avec le critère
        let foundParent;
       console.log("parentRef:",row.data.parentId)

        for (let el of this.sheets) {
          for (let article of el.children) {
            for (let processus of article.children) {
              if (processus.data._id === parentRef) foundParent = processus;
            };
          }
        }  
        console.log("foundParent:",foundParent)
        console.log("this.sheets:",this.sheets[0].children.map(el=>el))
        const formattedParent = this.formatRow(foundParent);
        formattedParent.details.push(this.formatRow(row));
        this.selectedRows.push(formattedParent);  
      } else {
        // Ajouter le critère sous le parent existant
        if (!parentNode.details.some((detail) => detail.ref === row.data._id)) {
          parentNode.details.push(this.formatRow(row));
        }
      }
    }
  }

  private removeFromSelectedRows(row: any) {
    if (!row.data.parentId) {
      // Cas d'un processus principal
      this.selectedRows = this.selectedRows.filter(
        (node) => node.ref !== row.data._id
      );
    } else {
      // Cas d'un critère
      const parentRef = row.data.parentId;
      const parentNode = this.selectedRows.find(
        (node) => node.ref === parentRef
      );

      if (parentNode) {
        // Retirer le critère du parent
        parentNode.details = parentNode.details.filter(
          (detail) => detail.ref !== row.data._id
        );

        // Si le parent n'a plus de détails, le retirer
        if (parentNode.details.length === 0) {
          this.selectedRows = this.selectedRows.filter(
            (node) => node.ref !== parentNode.ref
          );
        }
      }
    }

    // Supprimer les enfants récursivement
    if (this.hasChildren(row)) {
      row.children.forEach((child: any) => this.removeFromSelectedRows(child));
    }
  }

  private formatRow(row: any): any {
    if(row.level==3){
      return {
        ref: row.data._id,
        title: row.data.text || "Titre non défini",
        t:row.data.t,
        parentId:row.data.parentId
      }
    }else{
    return {
      ref: row.data._id,
      title: row.data.text || "Titre non défini",
      details: [],
    };
  }
  }

  private mergeDetails(existingDetails: any[], newDetails: any[]): any[] {
    const merged = [...existingDetails];

    newDetails.forEach((newDetail) => {
      const exists = merged.find((detail) => detail.ref === newDetail.ref);
      if (!exists) {
        merged.push(newDetail);
      }
    });

    return merged;
  }


  rowColor(level){
    //le-info text-center align-middle' : 'text-center w-100 align-middle
    if(level==2){
      return 'table-info  w-100  align-middle'
    }else if(level==3){
      return 'table-warning  w-100  align-middle'
    
    }else{
      return  ' w-100 align-middle'
    }
      }

  getRowData(row: any) {
    console.log("row:", row);
  }

  isChild(row: any) {
    return row.type !== "parent";
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
