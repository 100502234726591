import { AddLocationComponent } from './components/add-location/add-location.component';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiLocationsService } from './api-locations.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LocationsEditBtnComponent } from './components/locations.edit.btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit, OnDestroy {
  nbItems; loading; page; queryParams;
  editing: boolean;
  locations: Array<any> = [];
  hasMore = true;
  private subscriptions$: Subscription = new Subscription();

  settings;
  source: LocalDataSource;


  constructor(
    private route: ActivatedRoute,
    private apiLocationsService: ApiLocationsService,
    private windowLocation: NbWindowService,
    ) {
    this.source = new LocalDataSource(this.locations);
  }
 
  ngOnInit() {
    this.settings = {
      actions: {
        delete: false,
        add: false,
        edit: false,
      },
      columns: {
        
        _id: {
          title: 'Id',
          type: 'number',
          
        },
        name: {
          title: 'Lieu',
          type: 'string',
        },
        edit: {
          title:"Actions",
          type: 'custom',
          renderComponent: LocationsEditBtnComponent,
          filter: false,
          editable: false,
          width: '1%',
          sort: false
        },
      }
      
    };

    const sub = this.apiLocationsService.getlocations(this.queryParams).subscribe(res => {
      this.locations = [];
      this.locations = this.locations.concat(...res.data);
      this.apiLocationsService.locationsEmit(this.locations);
      sub.unsubscribe();
    }, err => {
      console.log(err);
      sub.unsubscribe();
    });
    this.subscriptions$.add(this.apiLocationsService.locations.subscribe(data => {
      if (data) {
        console.log("data:",data)
        this.locations = data;
        this.source.load(this.locations);
       localStorage.setItem("dataLocLength",data.length)
        console.log("source:",this.source)
        this.loading = false;
      }
    }));
    /*
    this.subscriptions$.add(
      this.route.queryParamMap.subscribe(params => {
        this.infiniteScrollDisabled = true;
        this.hasMore = true;
        this.page = 0;
        this.locations = [];
        this.queryParams = Object.assign({}, ...params.keys.map(key => ({
          [key]: params.getAll(key).join(',') // example : if the param was 'sort=xx&sort=yy', this.queryParams will be : {sort: 'xx,yy'}
        })));
        if (this.queryParams.search) {
          this.queryParams.sort = 'search';
        }
        // this.queryParams.limit = 5;
        this.loadNext();
      })
    );
    */
    
  }

  

  loadNext() {
    if (this.loading || !this.hasMore) { return; }

    this.loading = true;
    this.queryParams.page = this.page;

    const sub = this.apiLocationsService.getlocations(this.queryParams).subscribe(res => {
      this.locations = this.locations.concat(...res.data);
      this.apiLocationsService.locationsEmit(this.locations);
      this.nbItems = res.total_count;
      this.page++;
      this.hasMore = res.has_more;
      // this.infiniteScrollDisabled = false;
      this.loading = false;
      sub.unsubscribe();

    }, err => console.log(err));

  }

  onSearch(query: string = '') {
    this.source.setFilter([
      // fields we want to include in the search
      {
        field: 'name',
        search: query
      }
    ], true);
    // second parameter specifying whether to perform 'AND' or 'OR' search
    // (meaning all columns should contain search query or at least one)
    // 'AND' by default, so changing to 'OR' by setting false here
  }

  openAddLocationWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowLocation.open(AddLocationComponent, { title: 'Ajouter un lieu' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  
  
  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}


