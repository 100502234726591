import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiLocationsService {
  baseUrl = environment.apiEndpoint + '/locations';

  private locations$: Subject<any> = new BehaviorSubject<any>(null);

  get locations(): BehaviorSubject<any> {
    return this.locations$ as BehaviorSubject<any>;
  }

  locationsEmit(value: any) {
    this.locations$.next(value);
  }

  updatelocationsubject(locationData) {
    if (!locationData) { return; }

    const locationsArr = this.locations.value;

    for (const i in locationsArr) {
      if (locationsArr[i]._id === locationData._id) {
        locationsArr[i] = locationData;
        break;
      }
    }
    this.locationsEmit(locationsArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addLocation(locationData): Observable<any> {
    return this.http.post(this.baseUrl, locationData, { headers: this.auth.getHeaders() });
  }

  getlocations(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl, { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateLocation(locationId, locationData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + locationId, locationData, { headers: this.auth.getHeaders() });
  }
  
  deleteQuestion(locationId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + locationId, { headers: this.auth.getHeaders() });
  }
}
