import { DashboardService } from './../core/services/dashboard.service';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  private subscriptions$: Subscription = new Subscription();
  dashboardData: any;
  themeSubscription: any;

  constructor(
    private dashboardService: DashboardService
  ) { }

  ngOnInit() {
    this.subscriptions$.add(this.dashboardService.getDashboard().subscribe(data => {
      if (data) {
        this.dashboardService.dashboardEmit(data);
      }
    }));
    this.subscriptions$.add(this.dashboardService.dashboard.subscribe(data => {
      if (data) {
        this.dashboardData = data;
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }

}
