import { AuthLayoutComponent } from './shared/layouts/auth-layout/auth-layout.component';
import { PrintTemplateComponent } from './tasks-list/components/print-template/print-template.component';
import { LogtoutPageComponent } from './logout-page/logout-page.component';
import { AuthGuard } from './shared/classes/auth.guard';
import { TaskPageComponent } from './task-page/task-page.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SiteLayoutComponent } from './shared/layouts/site-layout/site-layout.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { NbAuthComponent, NbLogoutComponent } from '@nebular/auth';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersListComponent } from './users-list/users-list.component';
import { SettingsComponent } from './settings/settings.component';
import  {SheetsComponent } from './settings/sheets/sheets.component';
import { ServicesComponent } from './settings/services/services.component';
import { LocationsComponent } from './settings/locations/locations.component';
import { QuestionsComponent } from './settings/questions/questions.component';
import { QuizComponent } from './settings/quiz/quiz.component';
import { ExcelTemplateComponent } from './tasks-list/components/excel-template/excel-template.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
    },
    {
        path: '',
        component: SiteLayoutComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent },
            {
                path: 'tasks-list', component: TasksListComponent
            },
            { path: 'tasks-list/:id', component: TaskPageComponent },
            { path: 'users-list', component: UsersListComponent },
            { path: 'settings', component: SettingsComponent },
            { path: 'services-list', component: ServicesComponent },
            { path: 'locations-list', component: LocationsComponent },
            { path: 'questions-list', component: QuestionsComponent },
            { path: 'sheets-list', component: SheetsComponent },
            { path: 'quizzes-list', component: QuizComponent },
              
        ],
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
    {
        path: 'excel',
        component: ExcelTemplateComponent
    },
    {
        path: '', component: AuthLayoutComponent, children: [
            // { path: '', redirectTo: '/login', pathMatch: 'full' },
            { path: 'login', component: LoginPageComponent },
            // {path: 'register', component: RegisterPageComponent}
        ]
    },
    {
        path: 'logout',
        component: LogtoutPageComponent,
    }   
    
];
@NgModule({
    imports: [
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule {
}
