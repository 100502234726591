import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { NbThemeService } from '@nebular/theme';

@Component({
  selector: 'app-questions-stats',
  templateUrl: './questions-stats.component.html',
  styleUrls: ['./questions-stats.component.scss']
})
export class QuestionsStatsComponent implements OnInit, OnDestroy {
  private subscriptions$: Subscription = new Subscription();
  option: any;
  themeSubscription: any;
  // tslint:disable-next-line:variable-name
  _dashboardData;

  @Input('dashboardData')
  set dashboardData(value) {
    this._dashboardData = Object.assign({}, value);
    if (this._dashboardData && this._dashboardData.nb_questions >= 0 && this._dashboardData.nb_questions_answered >= 0) {
      this.createEchart();
    }
  }

  constructor(
    private theme: NbThemeService
  ) { }
  ngOnInit() {
  }

  createEchart() {
    this.subscriptions$.add(this.theme.getJsTheme().subscribe(config => {

      const questionsTheme: any = config.variables.questions;

      this.option = Object.assign({}, {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        series: [{
          name: ' ',
          clockWise: true,
          hoverAnimation: false,
          type: 'pie',
          center: ['45%', '50%'],
          radius: questionsTheme.radius,
          data: [
            {
              value: this._dashboardData.nb_questions_answered,
              name: ' ',
              label: {
                normal: {
                  position: 'center',
                  formatter: '{d}%',
                  textStyle: {
                    fontSize: '22',
                    fontFamily: config.variables.fontSecondary,
                    fontWeight: '600',
                    color: config.variables.fgHeading,
                  },
                },
              },
              tooltip: {
                show: false,
              },
              itemStyle: {
                normal: {
                  color: questionsTheme.gradientLeft,
                  shadowColor: questionsTheme.shadowColor,
                  shadowBlur: 0,
                  shadowOffsetX: 0,
                  shadowOffsetY: 3,
                },
              },
              hoverAnimation: false,
            },
            {
              value: this._dashboardData.nb_questions - this._dashboardData.nb_questions_answered,
              name: ' ',
              tooltip: {
                show: false,
              },
              label: {
                normal: {
                  position: 'inner',
                },
              },
              itemStyle: {
                normal: {
                  color: questionsTheme.secondSeriesFill,
                },
              },
            },
          ],
        }]
      });
    }));
  }

  ngOnDestroy() {
    this.subscriptions$.unsubscribe();
  }
}
