import { Component, OnInit, OnDestroy } from '@angular/core';
import { AddSheetComponent } from './components/add-sheet/add-sheet.component';
import { ApiSheetService } from './api-sheet.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
//import { QuestionsEditBtn } from './components/questions.edit.btn.component';
import { LocalDataSource } from '@vamidicreations/ng2-smart-table';
import { NbWindowService } from '@nebular/theme';
import { NbDialogService,NbDialogRef } from '@nebular/theme';
import { NbTreeGridDataSource, NbTreeGridDataSourceBuilder } from '@nebular/theme';
import { getParseTreeNode, isParseTreeNode } from 'typescript';
//import { ModifyQuestionComponent} from './components/modify-question/modify-question.component';
//import { DeleteQuestionComponent} from './components/delete-question/delete-question.component';
import { ApiService } from '../services/api-services.service';

interface TreeNode<T> {
  data: T;
  children?: TreeNode<T>[];
  expanded?: boolean;
}

interface FSEntry {
  _id: string;
  text: string;
  type:string;
  isSelected: boolean,

}

@Component({
  selector: 'app-sheets',
  templateUrl: './sheets.component.html',
  styleUrls: ['./sheets.component.scss']
})
export class SheetsComponent implements OnInit, OnDestroy {
  nbItems; loading; page; queryParams;
  editing: boolean;
  sheets: TreeNode<FSEntry>[] = [];
  hasMore = true;
  
  expanded: boolean;
  private subscriptions$: Subscription = new Subscription();
  customColumn = 'Réf';
  optionColumn = 'Options'
  defaultColumns = [ 'text' ];
  allColumns = [ this.customColumn, ...this.defaultColumns,this.optionColumn ];
 
  dataSource: NbTreeGridDataSource<FSEntry>;
  services: object[];

  constructor(dataSourceBuilder: NbTreeGridDataSourceBuilder<FSEntry>,
    private route: ActivatedRoute,
    private apiSheets: ApiSheetService,
    private apiService: ApiService,
   private windowQuestion: NbWindowService,
   private windowQuestion2: NbWindowService,
  private dialogQuestion: NbDialogService
   ) {
    this.dataSource = dataSourceBuilder.create(this.sheets);
  }
  private dialogRefQuestion:NbDialogRef<any>;


  ngOnInit() {
         
        const sub = this.apiSheets.getSheets(this.queryParams).subscribe(res => {
          
          this.sheets = [];
          console.log("res:", res)
          this.sheets = this.sheets.concat(...res.Sheets);
          this.apiSheets.sheetsEmit(this.sheets);
          sub.unsubscribe();
        }, err => {
          console.log(err);
          sub.unsubscribe();
        });
        this.subscriptions$.add(this.apiSheets.sheets.subscribe(data => {
          if (data) {
            console.log("data--------------:",data)
            

          let newList=data&&data.length>0?data.map((file)=>{
            
            return(
              //reformulation of data to be conform to dataSource ngGrid table
               { 
                //files
                data:{_id:file.title,text:file.category,type:"parent",t:'file',isSelected: false,},
                 children:file.details.map((article)=>({
                  //articles
                      data:{_id:article.ref,text:article.title,type:"child",t:'article',isSelected: false,},
                      children:article.processus.map((item)=>({
                        //processus
                          data:{_id:item.ref,text:item.title,type:"child",t:'processus',isSelected: false,},
                          children:item.criteres&&item.criteres.map((critere)=>({
                            //criteres
                                data:{_id:critere.ref,text:critere.title,type:"child",t:'critere',isSelected: false,}}
                    ))}))
                  
                 }))
                }
              
          )
                 
        }):console.log("liste vide!");

         
          console.log("newList:",newList)
          this.sheets = newList;
          
          this.dataSource.setData(this.sheets)
           localStorage.setItem("dataQLength",data.length)
            //console.log("source:",this.source)
            this.loading = false;
          }
        }));
      
        
      
        
      }
    
            
  openAddQuestionWindow() {
    this.editing = true;
    this.subscriptions$.add(
      this.windowQuestion.open(AddSheetComponent, { title: 'Ajouter un fichier' }).onClose.subscribe(() => {
        this.editing = false;
      })
    );
  }
  private data: FSEntry[] = [];
  
  // openEditWindow(row) {
  //   this.editing = true;
  //   this.subscriptions$.add(
  //     this.windowQuestion2.open(ModifyQuestionComponent, {
  //       title: 'Modifier cette question',
  //       context: { question: row }
  //     }).onClose.subscribe(() => {
  //       this.editing = false;
  //     })
  //   );
  // }

 

openAdd(closeOnBackdropClick: boolean,row:any) {
  console.log("row:",row)
 this.dialogRefQuestion= this.dialogQuestion.open(AddSheetComponent, {context: {sheet:row },closeOnBackdropClick:closeOnBackdropClick});
}

// Function to handle checkbox changes
onCheckboxChange(row: any) {
  row.data.isSelected = !row.data.isSelected;

  // If the row is expanded, update children recursively
  if (row.hasChildren()) {
    this.updateChildSelection(row, row.data.isSelected);
  }
}

// Recursive function to select or deselect children  
selectChildren(node: any, selected: boolean) {
  node.isSelected = selected;

  if (node.processus) {
    for (const processus of node.processus) {
      processus.isSelected = selected;
      for (const critere of processus.criteres) {
        critere.isSelected = selected;
      }
    }
  }
}


updateChildSelection(row: any, isSelected: boolean) {
  if (row.children) {
    row.children.forEach((child: any) => {
      child.data.isSelected = isSelected;
      if (child.hasChildren()) {
        this.updateChildSelection(child, isSelected);
      }
    });
  }
}



getRowData(row){
console.log("row:",row)
  }

  isChild(row){
    if(row.type==="parent")
    return false
    else return true
  }

  rowColor(level){
//le-info text-center align-middle' : 'text-center w-100 align-middle
if(level==2){
  return 'table-info  w-100  align-middle'
}else if(level==3){
  return 'table-warning  w-100  align-middle'

}else{
  return  ' w-100 align-middle '
}
  }

    ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }
}