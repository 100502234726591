import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiQuestions {
  baseUrl = environment.apiEndpoint + '/questions';

  private questions$: Subject<any> = new BehaviorSubject<any>(null);

  get questions(): BehaviorSubject<any> {
    return this.questions$ as BehaviorSubject<any>;
  }

  questionsEmit(value: any) {
    this.questions$.next(value);
  }

  updateQuestionSubject(questionData) {
    if (!questionData) { return; }

    const questionsArr = this.questions.value;

    for (const i in questionsArr) {
      if (questionsArr[i]._id === questionData._id) {
        questionsArr[i] = questionData;
        break;
      }
    }
    this.questionsEmit(questionsArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addQuestion(questionData): Observable<any> {
    return this.http.post(this.baseUrl, questionData, { headers: this.auth.getHeaders() });
  }

  getQuestions(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl+'/all', { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateQuestion(questionId, questionData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + questionId, questionData, { headers: this.auth.getHeaders() });
  }
  
  deleteQuestion(questionId): Observable<any> {
    return this.http.delete(this.baseUrl + '/' + questionId, { headers: this.auth.getHeaders() });
  }
}
