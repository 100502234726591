import { Component, OnDestroy, Input, HostBinding, OnInit } from '@angular/core';
import { Track, PlayerService } from 'src/app/core/services/player.service';
import { NbThemeService, NbMediaBreakpointsService, NbComponentSize } from '@nebular/theme';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-vocals-window',
  templateUrl: './task-vocals-window.component.html',
  styleUrls: ['./task-vocals-window.component.scss']
})
export class TaskVocalsWindowComponent implements OnInit, OnDestroy {
  collapsed; breakpoints; breakpoint;
  @Input() vocals;
  cardSize: NbComponentSize = 'small';

  private subscriptions$ = new Subscription();

  track: Track;
  player: HTMLAudioElement;
  shuffle: boolean;

  constructor(
    private playerService: PlayerService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService) {

    this.breakpoints = this.breakpointService.getBreakpointsMap();
    this.subscriptions$.add(this.themeService.onMediaQueryChange()
      .subscribe(([, newValue]) => {
        this.breakpoint = newValue;
        this.cardSize = newValue.width < this.breakpoints.md ? 'tiny' : (newValue.width < this.breakpoints.lg ? 'small' : 'medium');
        this.collapsed = newValue.width < this.breakpoints.md;
        console.log(this.cardSize);
      })
    );
  }

  ngOnInit(): void {
    this.playerService.setTracks(this.vocals);
    this.track = this.playerService.random();
    this.createPlayer();
  }

  ngOnDestroy() {
    this.player.pause();
    this.player.src = '';
    this.player.load();
    this.subscriptions$.unsubscribe();
  }

  isCollapsed() {
    return this.collapsed;
  }

  prev() {
    if (!this.player.loop) {
      if (this.shuffle) {
        this.track = this.playerService.random();
      } else {
        this.track = this.playerService.prev();
      }
    }

    this.reload();
  }

  next() {
    if (!this.player.loop) {
      if (this.shuffle) {
        this.track = this.playerService.random();
      } else {
        this.track = this.playerService.next();
      }
    }

    this.reload();
  }

  playPause() {
    if (this.player.paused) {
      this.player.play();
    } else {
      this.player.pause();
    }
  }

  toggleShuffle() {
    this.shuffle = !this.shuffle;
  }

  toggleLoop() {
    this.player.loop = !this.player.loop;
  }

  setVolume(volume: number) {
    this.player.volume = volume / 100;
  }

  getVolume(): number {
    return this.player.volume * 100;
  }

  setProgress(duration: number) {
    this.player.currentTime = this.player.duration * duration / 100;
  }

  getProgress(): number {
    return this.player.currentTime / this.player.duration * 100 || 0;
  }

  updateTime() {
    // A useless function just so the update event of the progress bar happens, used in ontimeupdate of Audio Element
    return this.player;
  }

  playVocal(id: number) {
    this.track = this.playerService.playTrack(id);
    this.reload();
  }

  private createPlayer() {
    this.player = new Audio();
    this.player.ontimeupdate = this.updateTime;
    this.player.onended = () => this.next();
    this.setTrack();
  }

  private reload() {
    this.setTrack();
    this.player.play();
  }

  private setTrack() {
    this.player.src = this.track.url;
    this.player.load();
  }

}
