import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  baseUrl = environment.apiEndpoint + '/users';

  private users$: Subject<any> = new BehaviorSubject<any>(null);

  get users(): BehaviorSubject<any> {
    return this.users$ as BehaviorSubject<any>;
  }

  usersEmit(value: any) {
    this.users$.next(value);
  }

  updateUserSubject(userData) {
    if (!userData) { return; }

    const userArr = this.users.value;

    for (const i in userArr) {
      if (userArr[i]._id === userData._id) {
        userArr[i] = userData;
        break;
      }
    }
    this.usersEmit(userArr);
  }

  constructor(private http: HttpClient, private auth: AuthService) { }

  addUser(userData): Observable<any> {
    return this.http.post(this.baseUrl, userData, { headers: this.auth.getHeaders() });
  }

  getUsers(queryParams: any = {}): Observable<any> {
    return this.http
      .get(this.baseUrl, { params: queryParams, headers: this.auth.getHeaders() });
  }

  updateUser(userId, userData): Observable<any> {
    return this.http.put(this.baseUrl + '/' + userId, userData, { headers: this.auth.getHeaders() });
  }
}
