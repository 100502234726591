import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import * as moment from 'moment';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3'
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF'
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA'
  }
};

@Component({
  selector: 'app-calendar-due-dates',
  templateUrl: './calendar-due-dates.component.html',
  styleUrls: ['./calendar-due-dates.component.scss']
})
export class CalendarDueDatesComponent implements OnInit {
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;
  disableNextTask = true;
  disablePrevTask = true;

  // tslint:disable-next-line:variable-name
  _dashboardData: any;
  @Input('dashboardData')
  set dashboardData(value) {
    this._dashboardData = value;
    if (value && value.dueDates) {
      this.events = [];
      this.refresh.next();
      this._dashboardData.dueDates = this._dashboardData.dueDates.sort((a, b) => moment(b.dueDate).valueOf() - moment(b.dueDate).valueOf());
      this._dashboardData.dueDates.forEach(data => {
        this.events.push({
          start: new Date(moment(data.dueDate).toDate()),
          end: new Date(moment(data.dueDate).toDate()),
          title: 'Tâche',
          color: colors.red,
          allDay: true,
        });
      });
      this.refresh.next();
      this.checkTaskBtnsDisableNoI();
    }
  }
  events: CalendarEvent[] = [];
  refresh: Subject<any> = new Subject();

  constructor() { }

  ngOnInit() {
  }

  setView(view: CalendarView) {
    this.view = view;
  }
  getNextMonth() {
    return moment(this.viewDate).add(1, 'M').toDate();
  }

  setDateToPreviousTask() {
    if (this._dashboardData.dueDates) {
      const curMonth = moment(this.viewDate).month();
      for (let i = 0; i < this._dashboardData.dueDates.length; i++) {
        if (moment(this._dashboardData.dueDates[i].dueDate).month() < curMonth) {
          this.viewDate = new Date(moment(this._dashboardData.dueDates[i].dueDate).toDate());
          this.checkTaskBtnsDisableWithI(i);
          break;
        }
      }
    }
  }

  setDateToNextTask() {
    if (this._dashboardData.dueDates) {
      const curMonth = moment(this.viewDate).month();
      for (let i = 0; i < this._dashboardData.dueDates.length; i++) {
        if (moment(this._dashboardData.dueDates[i].dueDate).month() > curMonth) {
          this.viewDate = new Date(moment(this._dashboardData.dueDates[i].dueDate).toDate());
          this.checkTaskBtnsDisableWithI(i);
          break;
        }
      }
    }
  }

  checkTaskBtnsDisableWithI(i: number) {
    console.log(i);
    if (i === 0) {
      this.disablePrevTask = true;
    } else {
      this.disablePrevTask = false;
    }
    if (i === this._dashboardData.dueDates.length - 1) {
      this.disableNextTask = true;
    } else {
      this.disableNextTask = false;
    }
  }
  checkTaskBtnsDisableNoI() {

    const prev = this._dashboardData.dueDates.findIndex(el => moment(el.dueDate).month() < moment(this.viewDate).month());
    const next = this._dashboardData.dueDates.findIndex(el => moment(el.dueDate).month() > moment(this.viewDate).month());
    if (prev === -1) {
      this.disablePrevTask = true;
    } else {
      this.disablePrevTask = false;
    }
    if (next === -1) {
      this.disableNextTask = true;
    } else {
      this.disableNextTask = false;
    }
  }

}
