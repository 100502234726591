import { Component, OnInit } from '@angular/core';
import { NbWindowService } from '@nebular/theme';
import { AddTaskComponent } from 'src/app/add-task/add-task.component';

@Component({
  selector: 'app-add-btn',
  templateUrl: './add-btn.component.html',
  styleUrls: ['./add-btn.component.scss']
})
export class AddBtnComponent implements OnInit {

  constructor(
    private windowService: NbWindowService) { }

  ngOnInit() {
  }

  openWindow() {
    this.windowService.open(AddTaskComponent, { title: 'Ajouter une visite' });
  }
}
