import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { NbComponentSize, NbThemeService, NbMediaBreakpointsService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-images-window',
  templateUrl: './task-images-window.component.html',
  styleUrls: ['./task-images-window.component.scss']
})

export class TaskImagesWindowComponent implements OnInit, OnDestroy {
  isSingleView;
  selectedCamera;
  @Input() question;
  @Input() images;
  
  actionSize: NbComponentSize = 'medium';
  cardSize: NbComponentSize = 'tiny';
  clicked = false;

  private subscriptions$ = new Subscription();
  private size$ = new Subscription();

  constructor(
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
  ) { }

  ngOnInit() {
    this.selectedCamera = this.images[0];
  }

  toggleSize() {
    if (this.clicked) {
      this.size$.unsubscribe();
      this.cardSize = 'tiny';
      this.clicked = false;
    } else {
      this.clicked = true;
      const breakpoints = this.breakpointService.getBreakpointsMap();
      this.size$.add(
        this.themeService.onMediaQueryChange()
          .pipe(map(([, breakpoint]) => breakpoint.width))
          .subscribe((width: number) => {
            this.actionSize = width > breakpoints.md ? 'medium' : 'small';
            this.cardSize = width < breakpoints.md ? 'small' : (width < breakpoints.lg ? 'medium' : 'large');
          })
      );
    }
  }

  changeSize() {
    if (!this.clicked) {
      this.clicked = true;
      const breakpoints = this.breakpointService.getBreakpointsMap();
      this.size$.add(
        this.themeService.onMediaQueryChange()
          .pipe(map(([, breakpoint]) => breakpoint.width))
          .subscribe((width: number) => {
            this.actionSize = width > breakpoints.md ? 'medium' : 'small';
            this.cardSize = width < breakpoints.md ? 'small' : (width < breakpoints.lg ? 'medium' : 'large');
          })
      );
    }
  }

  selectCamera(camera: any) {
    this.selectedCamera = camera;
    this.isSingleView = true;
  }

  getExtension(fileName:string):any{
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(fileName)[1]; 
    return ext=="mp4"?"mp4":"img"
  }


 


  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

}
