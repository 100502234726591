import { Component, OnInit ,} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import { NbWindowRef, NbDateService, NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from '../../api-services.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-delete-service',
  templateUrl: './delete-service.component.html',
  styleUrls: ['./delete-service.component.scss']
})
export class DeleteServiceComponent implements OnInit {
  private subscriptions$ = new Subscription();
  loading: boolean;
  name:string;
  service:any;
  constructor(
    protected dialogRef: NbDialogRef<any>, 
    private apiService: ApiService,
    private toastService: NbToastrService,
    private router: Router) { }

  ngOnInit(): void {
this.name=this.service.name;
  }
  close() {
    this.dialogRef.close();
  }

deleteService(){
  this.loading=true;
  this.subscriptions$.add(this.apiService.deleteService(this.service._id).subscribe(
    data => {
      this.loading = false;
      if (data.success) {
        this.dialogRef.close();
        this.toastService.success('', 'Le service a été supprimé avec succès');
       
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigateByUrl('/services-list');
        });
      } else {
        this.toastService.warning(data.message, 'Échec de la suppression du service!');
      }
    }
  )
);
}



}
